import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "../../interfaces/core.interface";
import {
  InventoryProduct,
  InventoryProductFilter,
} from "../../interfaces/inventory-product.interface";
import { SettingsService } from "../settings.service";
import { UtilService } from "../util.service";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/";
  }

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private utilService: UtilService
  ) {}

  getInventoryProduct(id: number): Observable<InventoryProduct> {
    return this.http.get<InventoryProduct>(
      this.apiUrl.concat(`v1/sales/inventory/public/products/${id}/`)
    );
  }

  getInventoryProducts(
    filter: InventoryProductFilter = null
  ): Observable<PaginatedResponse<InventoryProduct>> {
    const params = this.utilService.createHttpParams(filter);
    return this.http.get<PaginatedResponse<InventoryProduct>>(
      this.apiUrl.concat(`v1/sales/inventory/public/products/`),
      {
        params: params,
      }
    );
  }
}
