import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { MatChipList, MatChipSelectionChange } from "@angular/material/chips";
import { MatCalendar, MatCalendarView } from "@angular/material/datepicker";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { ScheduleService } from "src/app/shared/services/agenda/schedule.service";

export interface ScheduleInterval {
  slotId: number;
  startsAt: moment.Moment;
  endsAt: moment.Moment;
}

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
})
export class ScheduleComponent implements AfterViewInit, OnDestroy {
  @ViewChild("calendarRef") calendarRef: MatCalendar<moment.Moment>;
  @ViewChild("chipListRef") chipListRef: MatChipList;

  initialized = false;
  selectedDate = null;
  calendarView: MatCalendarView = "month";

  private _agendaId = null;
  private _appointmentType = null;
  private _cacheDebounceTime = 1; // in minutes
  private _cacheDebounce = {};
  private _cachedSchIntvls = {};
  private _calendarStateChangesSub: Subscription;

  @Input()
  get agendaId(): number {
    return this._agendaId;
  }
  set agendaId(agendaId) {
    if (agendaId == null) {
      this._agendaId = null;
      this.resetCalendar();
      return;
    }
    if (agendaId === this._agendaId) {
      return;
    }

    this._agendaId = agendaId;
    if (this._appointmentType != null) {
      this.initCalendar();
    }
  }

  @Input()
  get appointmentType(): string {
    return this._appointmentType;
  }
  set appointmentType(appointmentType) {
    if (appointmentType == null) {
      this._appointmentType = null;
      this.resetCalendar();
      return;
    }
    if (appointmentType == this._appointmentType) {
      return;
    }

    this._appointmentType = appointmentType;
    if (this._agendaId != null) {
      this.initCalendar();
    }
  }

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  constructor(private scheduleService: ScheduleService) {}

  ngAfterViewInit(): void {
    this._calendarStateChangesSub = this.calendarRef.stateChanges.subscribe(
      () => {
        if (!this.initialized) {
          return;
        }

        if (this.calendarRef.currentView === "month") {
          const date = moment(this.calendarRef.activeDate);
          this.fetchSchIntvlsMonth(date);
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this._calendarStateChangesSub) {
      this._calendarStateChangesSub.unsubscribe();
    }
  }

  initCalendar(): void {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this._cachedSchIntvls = {};

    const date = moment();
    const month = date.month() + 1;
    const year = date.year();

    const filter = {
      appointment_type: this.appointmentType,
      from_month: month,
      to_month: month,
      from_year: year,
      to_year: year + 1,
    };
    this.scheduleService.getNextSlotAvailable(this.agendaId, filter).subscribe({
      next: (val) => {
        const dateAvailable = moment(val[1]);
        this.selectedDate = dateAvailable;
        this.fetchSchIntvlsMonth(dateAvailable);
      },
    });
  }

  resetCalendar(): void {
    this.initialized = false;
    this.selectedDate = null;
    this._cachedSchIntvls = {};
  }

  fetchSchIntvlsMonth(date: moment.Moment): void {
    const now = moment.now();
    const year = date.year();
    const month = date.month() + 1;
    const key = `${year}.${month}`;
    const lastCall = this._cacheDebounce[key];
    const debounceTime = this._cacheDebounceTime;

    if (lastCall != null) {
      const timeDiff = moment(lastCall).diff(now, "minutes");
      if (timeDiff < debounceTime) {
        return;
      }
    }

    this._cacheDebounce[key] = now;

    const fromMonth = month;
    const fromYear = year;
    let toMonth: number;
    let toYear: number;

    if (fromMonth === 12) {
      toMonth = 1;
      toYear = fromYear + 1;
    } else {
      toMonth = fromMonth + 1;
      toYear = fromYear;
    }

    const filter = {
      appointment_type: this.appointmentType,
      from_month: fromMonth,
      from_year: fromYear,
      to_month: toMonth,
      to_year: toYear,
    };
    this.scheduleService.getSlotsAvailable(this.agendaId, filter).subscribe({
      next: (val) => {
        this._cachedSchIntvls[key] = this._noDuplicateSlots(val);
        this.calendarRef.updateTodaysDate();
      },
      error: (err) => {
        console.error(err);
        this._cachedSchIntvls[key] = null;
        this._cacheDebounce[key] = null;
      },
    });
  }

  private _noDuplicateSlots(val) {
    const results = {};

    for (const [date, slots] of Object.entries(val)) {
      results[date] = [];
      let previous = null;

      for (const slot of slots as []) {
        if (
          previous &&
          slot[1] == previous.startsAt &&
          slot[2] == previous.endsAt
        ) {
          continue;
        }

        const schIntvl = {
          slotId: slot[0],
          startsAt: slot[1],
          endsAt: slot[2],
        };
        results[date].push(schIntvl);
        previous = schIntvl;
      }
    }
    return results;
  }

  getSchIntvlsDate(date: moment.Moment): Array<ScheduleInterval> | null {
    if (!date) {
      return [];
    }

    const year = date.year();
    const month = date.month() + 1;
    const monthKey = `${year}.${month}`;
    const dateKey = date.format("YYYY-MM-DD");
    const cachedSchIntvlsMonth = this._cachedSchIntvls[monthKey];

    if (!cachedSchIntvlsMonth) {
      return null;
    }
    return cachedSchIntvlsMonth[dateKey] || [];
  }

  dateFilter = (date: moment.Moment): boolean => {
    // No calendar reference
    if (!this.calendarRef) {
      return false;
    }

    // No slots available in the past
    if (date.isBefore(moment())) {
      return false;
    }

    // We lazy evaluate months and years to avoid flooding the server
    const calendarView = this.calendarRef.currentView;
    if (calendarView === "multi-year" || calendarView === "year") {
      // Return true if we don't have the answer yet
      const schIntvlsDate = this.getSchIntvlsDate(date);
      if (schIntvlsDate == null) {
        return true;
      }

      // If we already have the answer we filter accordingly
      return schIntvlsDate.length >= 1;
    }

    const schIntvlsDate = this.getSchIntvlsDate(date) || [];
    return schIntvlsDate.length >= 1;
  };

  onSelectDate(date: moment.Moment): void {
    this.selectedDate = date;
    this.calendarRef.updateTodaysDate();
  }

  onCalendarViewChange(view: MatCalendarView) {
    if (view === "month" && view !== this.calendarView) {
      const date = moment(this.calendarRef.activeDate);
      this.fetchSchIntvlsMonth(date);
    }
    this.calendarView = view;
    this.calendarRef.updateTodaysDate();
  }

  onChipSelectionChange(
    event: MatChipSelectionChange,
    schIntvl: ScheduleInterval
  ): void {
    if (!this.selectedDate || !event.isUserInput) {
      return;
    }

    if (event.selected) {
      this.selectedChange.emit(schIntvl);
    } else {
      this.selectedChange.emit(null);
    }
  }

  deselectChips() {
    if (this.chipListRef == null) {
      return;
    }

    this.chipListRef.chips.forEach((chip) => {
      if (chip.selected) chip.deselect();
    });
    this.selectedChange.emit(null);
  }
}
