<div
  *ngIf="categories.length"
  [class.open]="collapse"
  class="collection-collapse-block border-0"
>
  <h3 class="collapse-block-title" (click)="collapse = !collapse">Categoria</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <li
          *ngFor="let category of categories"
          [class.active]="category.id === selectedCategoryId"
        >
          <a (click)="onCategoryClick(category.id)">
            {{ category.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
