import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import {
  BehaviorSubject,
  catchError,
  Observable,
  retry,
  share,
  Subscription,
  tap,
  throwError,
  timer,
} from "rxjs";
import { EnumAlert } from "../../enum/alert.enum";
import { BasketItem } from "../../interfaces/basket.interface";
import { PaginatedResponse } from "../../interfaces/core.interface";
import { InventoryProduct } from "../../interfaces/inventory-product.interface";
import { AuthService } from "../auth/auth.service";
import { UserService } from "../users/user.service";
import { SettingsService } from "../settings.service";
import { UtilService } from "../util.service";

@Injectable({
  providedIn: "root",
})
export class BasketService implements OnDestroy {
  readonly basket$: Observable<any>;

  private _userSub: Subscription;
  private _basketSubject: BehaviorSubject<any>;
  private _basket$: Observable<any>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private settingsService: SettingsService,
    private utilService: UtilService
  ) {
    this._basketSubject = new BehaviorSubject<any>(null);
    this.basket$ = this._basketSubject.asObservable();
    this._userSub = this.userService.user$.subscribe((val) => {
      if (val == null) {
        this._basketSubject.next(null);
        return;
      }
      this.fetchBasket();
    });
  }

  get basket() {
    return this._basketSubject.getValue();
  }

  get basketId(): number | null {
    return this.userService.userId;
  }

  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/v1/sales/basket/";
  }

  ngOnDestroy(): void {
    this._userSub.unsubscribe();
  }

  fetchBasket() {
    if (!this.authService.isLoggedIn) return;
    this.getBasket(this.basketId).subscribe();
  }

  getBasket(basketId: number): Observable<any> {
    console.info(EnumAlert.RetrieveBasketInit);
    if (!this._basket$) {
      this._basket$ = this.http.get(this.apiUrl.concat(`${basketId}/`)).pipe(
        retry({
          count: 5,
          delay: (err) => {
            if (err.status === 403) throw err;
            return timer(5000);
          },
        }),
        catchError((err) => {
          console.info(EnumAlert.RetrieveBasketError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveBasketSuccess);
          console.debug(val);
          this._basketSubject.next(val);
        }),
        share()
      );
    }
    return this._basket$;
  }

  createBasketItem(basketId: number, data: BasketItem): Observable<BasketItem> {
    console.info(EnumAlert.CreateBasketItemInit);
    return this.http
      .post<BasketItem>(this.apiUrl.concat(`${basketId}/items/`), data)
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.CreateBasketItemError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.CreateBasketItemSuccess);
          console.debug(val);
        })
      );
  }

  getBasketItem(
    basketId: number,
    basketItemId: number
  ): Observable<BasketItem> {
    console.info(EnumAlert.RetrieveBasketItemInit);
    return this.http
      .get<BasketItem>(this.apiUrl.concat(`${basketId}/items/${basketItemId}`))
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveBasketItemError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveBasketItemSuccess);
          console.debug(val);
        })
      );
  }

  updateBasketItem(
    basketId: number,
    basketItemId: number,
    data: BasketItem
  ): Observable<BasketItem> {
    console.info(EnumAlert.UpdateBasketItemInit);
    return this.http
      .put<BasketItem>(
        this.apiUrl.concat(`${basketId}/items/${basketItemId}/`),
        data
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.UpdateBasketItemError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.UpdateBasketItemSuccess);
          console.debug(val);
        })
      );
  }

  patchBasketItem(
    basketId: number,
    basketItemId: number,
    data: Partial<BasketItem>
  ): Observable<BasketItem> {
    console.info(EnumAlert.UpdateBasketItemInit);
    return this.http
      .patch<BasketItem>(
        this.apiUrl.concat(`${basketId}/items/${basketItemId}/`),
        data
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.UpdateBasketItemError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.UpdateBasketItemSuccess);
          console.debug(val);
        })
      );
  }

  deleteBasketItem(
    basketId: number,
    basketItemId: number
  ): Observable<BasketItem> {
    console.info(EnumAlert.DeleteBasketItemInit);
    return this.http
      .delete<BasketItem>(
        this.apiUrl.concat(`${basketId}/items/${basketItemId}/`)
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.DeleteBasketItemError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.DeleteBasketItemSuccess);
          console.debug(val);
        })
      );
  }

  getBasketItems(
    basketId: number,
    filter = null
  ): Observable<PaginatedResponse<BasketItem>> {
    console.info(EnumAlert.RetrieveBasketItemsInit);
    const params = this.utilService.createHttpParams(filter);
    return this.http
      .get<PaginatedResponse<BasketItem>>(
        this.apiUrl.concat(`${basketId}/items/`),
        {
          params: params,
        }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveBasketItemsError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          if (val.count) {
            console.info(EnumAlert.RetrieveBasketItemsSuccess);
          } else {
            console.info(EnumAlert.RetrieveBasketItemsEmpty);
          }
          console.debug(val);
        })
      );
  }

  previewCheckout(basketId: number, data): Observable<any> {
    console.info(EnumAlert.PreviewCheckoutInit);
    return this.http
      .post(this.apiUrl.concat(`${basketId}/checkout_preview/`), data)
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.PreviewCheckoutError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.PreviewCheckoutSuccess);
          console.debug(val);
        })
      );
  }

  checkout(basketId: number, data): Observable<any> {
    console.info(EnumAlert.CheckoutInit);
    return this.http
      .post(this.apiUrl.concat(`${basketId}/checkout/`), data)
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.CheckoutError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.CheckoutSuccess);
          console.debug(val);
        })
      );
  }

  navigateToProdCustomPage(inventoryProduct: InventoryProduct): boolean {
    if (inventoryProduct.product_type === "PLACA_AUTOMOTIVA") {
      this.router.navigate(["/shop/service/license-plate/schedule"], {
        queryParams: { store: inventoryProduct.store_id },
      });
      return true;
    }
    return false;
  }
}
