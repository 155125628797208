<ng-template class="theme-modal" #quickView let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body">
      <button
        type="button"
        class="close float-end border-0"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row">
        <div class="col-lg-6 col-xs-12">
          <div class="quick-view-img">
            <img
              [src]="product.images[0].url"
              [alt]="product.images[0].alt_text"
              defaultImage="assets/images/product/placeholder.jpg"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="col-lg-6 rtl-text">
          <div class="product-right">
            <div class="mb-3">
              <h2>{{ product.product_name }}</h2>
              <h3 *ngIf="discount">
                {{ currencyService.toLocalCurrency(discountedPrice) }}
                <del *ngIf="product?.discount">
                  <span class="money">
                    {{ currencyService.toLocalCurrency(unitPrice) }}
                  </span>
                </del>
              </h3>
              <h4>{{ product.product_specs }}</h4>
            </div>
            <div
              *ngIf="product.product_description.length"
              class="border-product"
            >
              <h6 class="product-title">Descrição</h6>
              <p>{{ product.product_description | truncate : 200 : "..." }}</p>
            </div>
            <div class="product-description border-product">
              <h5 class="avalibility" *ngIf="quantity <= product.stock_qty">
                <span>{{ product.stock_qty }} unidades disponíveis</span>
              </h5>
              <h5 class="avalibility" *ngIf="quantity > product.stock_qty">
                <span>Fora de estoque</span>
              </h5>
              <h6 class="product-title">Quantidade</h6>
              <div class="qty-box">
                <div class="input-group">
                  <span class="input-group-prepend">
                    <button
                      type="button"
                      class="btn quantity-left-minus"
                      data-type="minus"
                      (click)="decrement()"
                    >
                      <i class="ti-angle-left"></i>
                    </button>
                  </span>
                  <input
                    type="text"
                    name="quantity"
                    class="form-control input-number"
                    [value]="quantity"
                    disabled
                  />
                  <span class="input-group-prepend">
                    <button
                      type="button"
                      class="btn quantity-right-plus"
                      data-type="plus"
                      (click)="increment()"
                    >
                      <i class="ti-angle-right"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex product-buttons">
              <a
                [class.disabled]="quantity > product.stock_qty"
                (click)="addToBasket(product)"
                class="btn btn-solid"
              >
                Adicionar ao carrinho
              </a>
              <a
                [routerLink]="['/shop/collection/infinitescroll/', product.id]"
                class="btn btn-solid"
              >
                Ver detalhes
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
