<div class="img-wrapper">
  <div class="lable-block" *ngIf="product.new">
    <span class="lable3">new</span>
  </div>
  <div class="front">
    <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
      <img
        [defaultImage]="'assets/images/product/placeholder.jpg'"
        [lazyLoad]="product.images[0].url"
        class="img-fluid"
        alt="{{ product.images[0].alt_text }}"
      />
    </a>
  </div>
  <div class="cart-box">
    <button
      title="Add to cart"
      (click)="CartModal.openModal(product)"
      *ngIf="cartModal"
    >
      <i class="ti-shopping-cart"></i>
    </button>
    <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
      <i class="ti-shopping-cart"></i>
    </button>
    <a
      href="javascript:void(0)"
      (click)="addToWishlist(product)"
      title="Add to Wishlist"
    >
      <i class="ti-heart" aria-hidden="true"></i>
    </a>
    <a
      href="javascript:void(0)"
      class="mobile-quick-view"
      (click)="QuickView.openModal()"
      title="Quick View"
    >
      <i class="ti-search" aria-hidden="true"></i>
    </a>
    <a
      href="javascript:void(0)"
      (click)="addToCompare(product)"
      title="Compare"
    >
      <i class="ti-reload" aria-hidden="true"></i>
    </a>
  </div>
  <!-- <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div> -->
</div>
<div class="product-info">
  <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
  <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
    <h6>{{ product?.title | titlecase }}</h6>
  </a>
  <h4>
    {{
      product?.price * currencyConfig.price
        | discount : product
        | currency : currencyConfig.currency
    }}
  </h4>
</div>

<app-quick-view #quickView [product]="product"></app-quick-view>
<app-cart-modal #cartModal *ngIf="cartModal"></app-cart-modal>
