import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, Observable, tap } from "rxjs";
import { Settings } from "../classes/settings";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private settingsSubject = new BehaviorSubject<Settings>(null);

  readonly settings$ = this.settingsSubject
    .asObservable()
    .pipe(filter((val) => !!val));

  constructor(private http: HttpClient) {}

  get apiUrl(): string {
    const settings = this.settingsSubject.getValue();
    return settings.apiUrl;
  }

  setUp() {
    this.fetchSettings();
  }

  fetchSettings() {
    console.info("Obtendo as configurações da aplicação.");

    this.getSettings().subscribe({
      next: () => {
        console.info("Configurações da aplicação obtidas com sucesso.");
      },
      error: (err) => {
        console.error("Erro ao obter configurações da aplicação.");
        console.error(err);
      },
    });
  }

  getSettings(): Observable<any> {
    return this.http
      .get("assets/settings.json")
      .pipe(tap((val) => this.settingsSubject.next(val)));
  }
}
