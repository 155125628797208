import { AbstractControl, ValidationErrors } from "@angular/forms";

export function validateLat(control: AbstractControl): ValidationErrors {
  const value = control.value;
  if (value == null || value === "") {
    return null;
  }
  const lat = Number(value);
  if (isNaN(lat) || lat < -90 || lat > 90) {
    return { invalid: true };
  }

  return null;
}

export function validateLng(control: AbstractControl): ValidationErrors {
  const value = control.value;
  if (value == null || value === "") {
    return null;
  }

  const lng = Number(value);
  if (isNaN(lng) || lng < -180 || lng > 180) {
    return { invalid: true };
  }

  return null;
}
