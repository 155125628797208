import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  retry,
  share,
  Subscription,
  tap,
  timer,
} from "rxjs";
import { AuthService } from "../auth/auth.service";
import { SettingsService } from "../settings.service";

@Injectable({
  providedIn: "root",
})
export class UserService implements OnDestroy {
  readonly user$: Observable<any>;

  private _authSub: Subscription;
  private _userSubject: BehaviorSubject<any>;
  private _loggedUser$: Observable<any>;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private settingsService: SettingsService
  ) {
    this._userSubject = new BehaviorSubject<any>(null);
    this.user$ = this._userSubject.asObservable();
  }

  get user() {
    return this._userSubject.getValue();
  }

  get userId(): number {
    const value = localStorage.getItem("user.id");
    return parseInt(value) || null;
  }

  set userId(userId: number) {
    if (userId == null) localStorage.removeItem("user.id");
    else localStorage.setItem("user.id", userId.toString());
  }

  get firstName(): string {
    return localStorage.getItem("user.firstName");
  }

  set firstName(firstName: string) {
    if (firstName == null) localStorage.removeItem("user.firstName");
    else localStorage.setItem("user.firstName", firstName);
  }

  get lastName(): string {
    return localStorage.getItem("user.lastName");
  }

  set lastName(lastName: string) {
    if (lastName == null) localStorage.removeItem("user.lastName");
    else localStorage.setItem("user.lastName", lastName);
  }

  get email(): string {
    return localStorage.getItem("user.email");
  }

  set email(email: string) {
    if (email == null) localStorage.removeItem("user.email");
    else localStorage.setItem("user.email", email);
  }

  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/";
  }

  ngOnDestroy(): void {
    this._authSub.unsubscribe();
  }

  setUp(): void {
    this._authSub = this.authService.auth$.subscribe((val) => {
      if (!val) {
        console.info("Usuário desconectado.");
        this.userId = null;
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this._userSubject.next(null);
        return;
      }
      this.fetchLoggedUser();
    });
  }

  createUser(data): Observable<any> {
    return this.http.post(this.apiUrl.concat("v1/users/"), data);
  }

  getUsers(): Observable<any> {
    return this.http.get(this.apiUrl.concat("v1/users/"));
  }

  getUser(id: number): Observable<any> {
    return this.http.get(this.apiUrl.concat(`v1/users/${id}/`));
  }

  updateUser(id: number, data): Observable<any> {
    return this.http.put(this.apiUrl.concat(`v1/users/${id}/`), data);
  }

  patchUser(id: number, data): Observable<any> {
    return this.http.patch(this.apiUrl.concat(`v1/users/${id}/`), data);
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(this.apiUrl.concat(`v1/users/${id}/`));
  }

  sendActivationToken(email: string): Observable<any> {
    const data = { email: email };
    return this.http.post(
      this.apiUrl.concat(`v1/users/send_activation_token/`),
      data
    );
  }

  activateUserAccount(email: string, token: string): Observable<any> {
    const data = { email: email, token: token };
    return this.http.post(this.apiUrl.concat("v1/users/activate/"), data);
  }

  getLoggedUser(): Observable<any> {
    if (!this._loggedUser$) {
      this._loggedUser$ = this.http
        .get(this.apiUrl.concat(`v1/users/logged_user/`))
        .pipe(
          retry({
            count: 5,
            delay: (err) => {
              if (err.status === 403) throw err;
              return timer(5000);
            },
          }),
          tap((val: any) => {
            this.userId = val.id;
            this.firstName = val.first_name;
            this.lastName = val.last_name;
            this.email = val.email;
            this._userSubject.next(val);
          }),
          share()
        );
    }
    return this._loggedUser$;
  }

  fetchLoggedUser(): void {
    if (!this.authService.isLoggedIn) return;
    console.info("Obtendo informações do usuário conectado.");
    this.getLoggedUser().subscribe({
      next: () => {
        console.info("Informações do usuário conectado obtidas com sucesso.");
      },
      error: () => {
        console.error("Erro ao obter informações do usuário conectado.");
      },
    });
  }
}
