import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal, { SweetAlertOptions } from "sweetalert2/src/sweetalert2.js";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  warning(title, text = "") {
    Swal.fire({
      title: title,
      text: text,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      icon: "warning",
    });
  }

  success(title, text = "", html = false) {
    const options: SweetAlertOptions = {
      title: title,
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      icon: "success",
    };

    if (html) {
      options.html = text;
    } else {
      options.text = text;
    }

    Swal.fire(options);
  }

  error(title, text = "", html = false, customClass = null) {
    const options: SweetAlertOptions = {
      title: title,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      customClass: customClass,
    };

    if (html) {
      options.html = text;
    } else {
      options.text = text;
    }

    Swal.fire(options);
  }

  wait(options) {
    Swal.fire(
      Object.assign({}, options, {
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        html: `<div class="align-middle">
        <div class="spinner-border text-primary align-middle" role="status"></div>
      </div>`,
        footer: '<span class="align-middle">Por favor aguarde...</span>',
      })
    );
  }

  confirm(title, text?, html?): Observable<boolean> {
    return new Observable((subscriber) => {
      Swal.fire({
        title: title,
        text: text,
        html: html,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          subscriber.next(true);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          subscriber.next(false);
        }
      });
    });
  }

  inputText(title, text): Observable<string> {
    return new Observable((subscriber) => {
      Swal.fire({
        title: title,
        text: text,
        input: "text",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        inputValidator: (value) => {
          if (value == null || value === "") {
            return "O texto não pode estar vazio.";
          }
        },
      }).then((result) => {
        if (result.value) {
          subscriber.next(result.value);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          subscriber.next(null);
        }
      });
    });
  }

  inputChoice(
    title,
    text,
    inputOptions,
    inputPlaceholder?
  ): Observable<string> {
    return new Observable((subscriber) => {
      Swal.fire({
        title: title,
        text: text,
        input: "select",
        inputOptions: inputOptions,
        inputPlaceholder: inputPlaceholder,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        inputValidator: (value) => {
          if (value == null || value === "") {
            return "É preciso escolher uma opção.";
          }
        },
      }).then((result) => {
        if (result.value) {
          subscriber.next(result.value);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          subscriber.next(null);
        }
      });
    });
  }

  viewImage(title, imageUrl, imgFooter = null) {
    const html = ["<img width='600px' class='p-3' src=" + imageUrl + ">"];
    if (imgFooter) html.push("<div>" + imgFooter + "</div>");

    Swal.fire({
      title: title,
      html: html.join(""),
      confirmButtonColor: "#3085d6",
      width: "800px",
      confirmButtonText: "Fechar",
    });
  }

  close() {
    Swal.close();
  }
}
