import {
  Component,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormGroupName,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  debounceTime,
  distinctUntilChanged,
  pairwise,
  startWith,
  Subscription,
} from "rxjs";
import { Address } from "../../interfaces/address.interface";
import { AddressService } from "../../services/enterprise/address.service";

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true,
    },
  ],
})
export class AddressFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() isLarge = false;
  @Input() showError = false;

  form: FormGroup;
  subscription: Subscription;

  constructor(
    private injector: Injector,
    private addressService: AddressService
  ) {}

  get value(): Address {
    return this.form.value;
  }

  set value(value: Address) {
    this.form.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    const formGroupDirective = this.injector.get(FormGroupDirective);

    try {
      const formGroupName = this.injector.get(FormGroupName);
      this.form = formGroupDirective.getFormGroup(formGroupName);
    } catch (error) {
      this.form = formGroupDirective.form;
    }

    this.subscription = this.form.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        startWith(this.form.value),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        if (
          prev.postcode !== next.postcode &&
          next.postcode != null &&
          next.postcode.length === 8
        ) {
          this.fetchLocationByPostcode();
        } else if (
          (prev.street !== next.street &&
            next.street != null &&
            next.street.length > 0) ||
          (prev.number !== next.number &&
            next.number != null &&
            next.number.length > 0)
        ) {
          this.fetchLocationByAddress();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  validate(_: FormControl): ValidationErrors {
    return this.form.valid ? null : { address: { valid: false } };
  }

  isFieldRequired(fieldName: string): boolean {
    const ctrl = this.form.get(fieldName);
    return ctrl && ctrl.hasValidator(Validators.required);
  }

  fetchLocationByPostcode(): void {
    const postcode = this.form.get("postcode").value;

    if (postcode.length !== 8) {
      return;
    }

    this.addressService
      .getLocationByPostcode(postcode, ["VIACEP"])
      .subscribe((val) => {
        this.form.patchValue(val);
      });
  }

  fetchLocationByAddress(): void {
    const street = this.form.get("street").value;
    const number = this.form.get("number").value;

    if (
      street == null ||
      street.length === 0 ||
      number == null ||
      number.length == 0
    ) {
      return;
    }

    const address = this.addressService.formatAddress(this.form.value);
    this.addressService
      .getLocationByAddress(address, ["ARCGIS", "OSM"])
      .subscribe((val) => {
        this.form.patchValue({ location: val.location });
      });
  }
}
