import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// Menu
export interface Menu {
  path?: string;
  title?: string;
  type?: string;
  megaMenu?: boolean;
  image?: string;
  active?: boolean;
  badge?: boolean;
  badgeText?: string;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService {
  public screenWidth: any;
  public leftMenuToggle = false;
  public mainMenuToggle = false;

  // Windows width
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: "Início",
      type: "link",
      active: false,
      path: "/home",
    },
    {
      title: "Quem Somos",
      type: "link",
      active: false,
      path: "/pages/aboutus",
    },
    {
      title: "Produtos",
      type: "link",
      active: false,
      path: "/shop/collection/infinitescroll",
    },
    {
      title: "Serviços",
      type: "sub",
      megaMenu: true,
      active: false,
      children: [
        {
          title: "Para o seu veículo",
          type: "sub",
          active: false,
          children: [
            {
              path: "/shop/service/license-plate",
              title: "Emplacamento",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Vistoria",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Licenciamento",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Transferência",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Estacionamento",
              type: "link",
            },
          ],
        },
        {
          title: "Para a sua empresa",
          type: "sub",
          active: false,
          children: [
            {
              path: "/pages/comingsoon",
              title: "Estampadores de placas",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Fabricantes de placas",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Vistoriadores",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Despachantes",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Torne-se um franqueado",
              type: "link",
            },
          ],
        },
        {
          title: "Treinamentos",
          type: "sub",
          active: false,
          children: [
            {
              path: "/pages/comingsoon",
              title: "Emplacamento",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Vistoria veicular",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Vistoria cautelar",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Gestão de frota",
              type: "link",
            },
            {
              path: "/pages/comingsoon",
              title: "Análise técnica de sinistros",
              type: "link",
            },
          ],
        },
      ],
    },
    {
      title: "Contato",
      type: "link",
      active: false,
      path: "/pages/contact",
    },
  ];

  LEFTMENUITEMS: Menu[] = [
    {
      path: "/shop/service/license-plate",
      title: "Emplacamento",
      type: "link",
    },
    {
      path: "/pages/comingsoon",
      title: "Vistoria",
      type: "link",
    },
    {
      path: "/pages/comingsoon",
      title: "Licenciamento",
      type: "link",
    },
    {
      path: "/pages/comingsoon",
      title: "Transferência",
      type: "link",
    },
    {
      path: "/pages/comingsoon",
      title: "Estacionamento",
      type: "link",
    },
  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);
}
