export enum EnumAlert {
  // Generic CRUD
  CreateInit = "Criando",
  CreateSuccess = "Criado",
  CreateError = "Erro ao criar",
  RetrieveInit = "Obtendo dados",
  RetrieveSuccess = "Dados obtidos",
  RetrieveError = "Erro ao obter dados",
  RetrieveEmpty = "Nenhum dado encontrado",
  UpdateInit = "Atualizando",
  UpdateSuccess = "Atualizado",
  UpdateError = "Erro ao atualizar",
  DeleteInit = "Excluindo",
  DeleteSuccess = "Excluído",
  DeleteError = "Erro ao excluir",

  // Generic actions
  PreparationInit = "Preparando dados",
  PreparationSuccess = "Dados preparados",
  PreparationError = "Erro ao preparar dados",
  OperationInit = "Iniciando operação",
  OperationSuccess = "Operação concluída",
  OperationError = "Erro ao realizar operação",
  DownloadInit = "Iniciando download",
  DownloadSuccess = "Download concluído",
  DownloadError = "Erro ao realizar download",

  // Generic errors
  NotFound = "Não encontrado",

  // Customers
  CreateCustomerInit = "Criando consumidor",
  CreateCustomerSuccess = "Consumidor criado",
  CreateCustomerError = "Erro ao criar consumidor",
  RetrieveCustomerInit = "Obtendo consumidor",
  RetrieveCustomerSuccess = "Consumidor obtido",
  RetrieveCustomerError = "Erro ao obter consumidor",
  UpdateCustomerInit = "Atualizando consumidor",
  UpdateCustomerSuccess = "Consumidor atualizado",
  UpdateCustomerError = "Erro ao atualizar consumidor",
  DeleteCustomerInit = "Excluindo consumidor",
  DeleteCustomerSuccess = "Consumidor excluído",
  DeleteCustomerError = "Erro ao excluir consumidor",
  RetrieveCustomersInit = "Obtendo consumidores",
  RetrieveCustomersSuccess = "Consumidores obtidos",
  RetrieveCustomersError = "Erro ao obter consumidores",
  RetrieveCustomersEmpty = "Nenhum consumidor encontrado",

  // Orders
  CreateOrderInit = "Criando pedido",
  CreateOrderSuccess = "Pedido criado",
  CreateOrderError = "Erro ao criar pedido",
  RetrieveOrderInit = "Obtendo pedido",
  RetrieveOrderSuccess = "Pedido obtido",
  RetrieveOrderError = "Erro ao obter pedido",
  UpdateOrderInit = "Atualizando pedido",
  UpdateOrderSuccess = "Pedido atualizado",
  UpdateOrderError = "Erro ao atualizar pedido",
  DeleteOrderInit = "Excluindo pedido",
  DeleteOrderSuccess = "Pedido excluído",
  DeleteOrderError = "Erro ao excluir pedido",
  RetrieveOrdersInit = "Obtendo pedidos",
  RetrieveOrdersSuccess = "Pedidos obtidos",
  RetrieveOrdersError = "Erro ao obter pedidos",
  RetrieveOrdersEmpty = "Nenhum pedido encontrado",

  // Order cancellations
  CreateOrderCancellationInit = 'Criando cancelamento',
  CreateOrderCancellationSuccess = 'Cancelamento criado',
  CreateOrderCancellationError = 'Erro ao criar cancelamento',
  RetrieveOrderCancellationInit = 'Obtendo cancelamento',
  RetrieveOrderCancellationSuccess = 'Cancelamento obtido',
  RetrieveOrderCancellationError = 'Erro ao obter cancelamento',
  UpdateOrderCancellationInit = 'Atualizando cancelamento',
  UpdateOrderCancellationSuccess = 'Cancelamento atualizado',
  UpdateOrderCancellationError = 'Erro ao atualizar cancelamento',
  DeleteOrderCancellationInit = 'Excluindo cancelamento',
  DeleteOrderCancellationSuccess = 'Cancelamento excluído',
  DeleteOrderCancellationError = 'Erro ao excluir cancelamento',
  RetrieveOrderCancellationsInit = 'Obtendo cancelamentos',
  RetrieveOrderCancellationsSuccess = 'Cancelamentos obtidos',
  RetrieveOrderCancellationsError = 'Erro ao obter cancelamentos',
  RetrieveOrderCancellationsEmpty = 'Nenhuma cancelamento encontrado',
  RetrieveOrderCancellationSchemaInit = 'Obtendo esquema de cancelamentos',
  RetrieveOrderCancellationSchemaSuccess = 'Esquema de cancelamentos obtido',
  RetrieveOrderCancellationSchemaError = 'Erro ao obter esquema de cancelamentos',
  AcceptOrderCancellationInit = 'Aceitando cancelamento',
  AcceptOrderCancellationSuccess = 'Cancelamento aceito',
  AcceptOrderCancellationError = 'Erro ao aceitar cancelamento',
  DenyOrderCancellationInit = 'Negando cancelamento',
  DenyOrderCancellationSuccess = 'Cancelamento negado',
  DenyOrderCancellationError = 'Erro ao negar cancelamento',

  // Order cancellation logs
  RetrieveOrderCancellationLogsInit = 'Obtendo registros de cancelamento',
  RetrieveOrderCancellationLogsSuccess = 'Registros de cancelamento obtidos',
  RetrieveOrderCancellationLogsError = 'Erro ao obter registros de cancelamento',
  RetrieveOrderCancellationLogsEmpty = 'Nenhum registro de cancelamento encontrado',
  RetrieveOrderCancellationLogSchemaInit = 'Obtendo esquema de registros de devoluções',
  RetrieveOrderCancellationLogSchemaSuccess = 'Esquema de registros de devoluções obtido',
  RetrieveOrderCancellationLogSchemaError = 'Erro ao obter esquema de registros de devoluções',

  // Product categories
  CreateProductCategoryInit = "Criando categoria de produto",
  CreateProductCategorySuccess = "Categoria de produto criada",
  CreateProductCategoryError = "Erro ao criar categoria de produto",
  RetrieveProductCategoryInit = "Obtendo categoria de produto",
  RetrieveProductCategorySuccess = "Categoria de produto obtida",
  RetrieveProductCategoryError = "Erro ao obter categoria de produto",
  UpdateProductCategoryInit = "Atualizando categoria de produto",
  UpdateProductCategorySuccess = "Categoria de produto atualizada",
  UpdateProductCategoryError = "Erro ao atualizar categoria de produto",
  DeleteProductCategoryInit = "Excluindo categoria de produto",
  DeleteProductCategorySuccess = "Categoria de produto excluída",
  DeleteProductCategoryError = "Erro ao excluir categoria de produto",
  RetrieveProductCategoriesInit = "Obtendo categorias de produtos",
  RetrieveProductCategoriesSuccess = "Categorias de produtos obtidas",
  RetrieveProductCategoriesError = "Erro ao obter categorias de produtos",
  RetrieveProductCategoriesEmpty = "Nenhuma categoria de produto encontrada",

  // Invoices
  CreateInvoiceInit = 'Criando fatura',
  CreateInvoiceSuccess = 'Fatura criada',
  CreateInvoiceError = 'Erro ao criar fatura',
  RetrieveInvoiceInit = 'Obtendo fatura',
  RetrieveInvoiceSuccess = 'Fatura obtida',
  RetrieveInvoiceError = 'Erro ao obter fatura',
  UpdateInvoiceInit = 'Atualizando fatura',
  UpdateInvoiceSuccess = 'Fatura atualizada',
  UpdateInvoiceError = 'Erro ao atualizar fatura',
  DeleteInvoiceInit = 'Excluindo fatura',
  DeleteInvoiceSuccess = 'Fatura excluída',
  DeleteInvoiceError = 'Erro ao excluir fatura',
  RetrieveInvoicesInit = 'Obtendo faturas',
  RetrieveInvoicesSuccess = 'Faturas obtidas',
  RetrieveInvoicesError = 'Erro ao obter faturas',
  RetrieveInvoicesEmpty = 'Nenhuma fatura encontrada',
  RetrieveInvoiceSchemaInit = 'Obtendo esquema de faturas',
  RetrieveInvoiceSchemaSuccess = 'Esquema de faturas obtido',
  RetrieveInvoiceSchemaError = 'Erro ao obter esquema de faturas',

  // Product brands
  CreateProductBrandInit = "Criando marca de produto",
  CreateProductBrandSuccess = "Marca de produto criada",
  CreateProductBrandError = "Erro ao criar marca de produto",
  RetrieveProductBrandInit = "Obtendo marca de produto",
  RetrieveProductBrandSuccess = "Marca de produto obtida",
  RetrieveProductBrandError = "Erro ao obter marca de produto",
  UpdateProductBrandInit = "Atualizando marca de produto",
  UpdateProductBrandSuccess = "Marca de produto atualizada",
  UpdateProductBrandError = "Erro ao atualizar marca de produto",
  DeleteProductBrandInit = "Excluindo marca de produto",
  DeleteProductBrandSuccess = "Marca de produto excluída",
  DeleteProductBrandError = "Erro ao excluir marca de produto",
  RetrieveProductBrandsInit = "Obtendo marcas de produtos",
  RetrieveProductBrandsSuccess = "Marcas de produtos obtidas",
  RetrieveProductBrandsError = "Erro ao obter marcas de produtos",
  RetrieveProductBrandsEmpty = "Nenhuma marca de produto encontrada",

  // Products
  CreateProductInit = "Criando produto",
  CreateProductSuccess = "Produto criado",
  CreateProductError = "Erro ao criar produto",
  RetrieveProductInit = "Obtendo produto",
  RetrieveProductSuccess = "Produto obtido",
  RetrieveProductError = "Erro ao obter produto",
  UpdateProductInit = "Atualizando produto",
  UpdateProductSuccess = "Produto atualizado",
  UpdateProductError = "Erro ao atualizar produto",
  DeleteProductInit = "Excluindo produto",
  DeleteProductSuccess = "Produto excluído",
  DeleteProductError = "Erro ao excluir produto",
  RetrieveProductsInit = "Obtendo produtos",
  RetrieveProductsSuccess = "Produtos obtidos",
  RetrieveProductsError = "Erro ao obter produtos",
  RetrieveProductsEmpty = "Nenhum produto encontrado",
  RetrieveRecentProductsInit = "Obtendo produtos recentes",
  RetrieveRecentProductsSuccess = "Produtos recentes obtidos",
  RetrieveRecentProductsError = "Erro ao obter produtos recentes",
  RetrieveRecentProductsEmpty = "Nenhum produto recente encontrado",

  // Basket
  RetrieveBasketInit = "Obtendo carrinho de compras",
  RetrieveBasketSuccess = "Carrinho de compras obtido",
  RetrieveBasketError = "Erro ao obter carrinho de compras",

  // Basket items
  CreateBasketItemInit = "Adicionando item ao carrinho",
  CreateBasketItemSuccess = "Item adicionado ao carrinho",
  CreateBasketItemError = "Erro ao adicionar item ao carrinho",
  RetrieveBasketItemInit = "Obtendo item do carrinho",
  RetrieveBasketItemSuccess = "Item do carrinho obtido",
  RetrieveBasketItemError = "Erro ao obter item do carrinho",
  UpdateBasketItemInit = "Atualizando item do carrinho",
  UpdateBasketItemSuccess = "Item do carrinho atualizado",
  UpdateBasketItemError = "Erro ao atualizar item do carrinho",
  DeleteBasketItemInit = "Excluindo item do carrinho",
  DeleteBasketItemSuccess = "Item do carrinho excluído",
  DeleteBasketItemError = "Erro ao excluir item do carrinho",
  RetrieveBasketItemsInit = "Obtendo itens do carrinho",
  RetrieveBasketItemsSuccess = "Itens do carrinho obtidos",
  RetrieveBasketItemsError = "Erro ao obter itens do carrinho",
  RetrieveBasketItemsEmpty = "Nenhum item de carrinho encontrado",

  // Checkout
  CheckoutInit = "Iniciando checkout",
  CheckoutSuccess = "Checkout concluído",
  CheckoutError = "Erro ao realizar checkout",
  PreviewCheckoutInit = "Iniciando pré-visualização de checkout",
  PreviewCheckoutSuccess = "Pré-visualização de checkout obtida",
  PreviewCheckoutError = "Erro ao realizar pré-visualização de checkout",

  // Payments
  CreatePaymentInit = "Criando pagamento",
  CreatePaymentSuccess = "Pagamento criado",
  CreatePaymentError = "Erro ao criar pagamento",
  RetrievePaymentInit = "Obtendo pagamentoo",
  RetrievePaymentSuccess = "Pagamento obtido",
  RetrievePaymentError = "Erro ao obter pagamento",
  UpdatePaymentInit = "Atualizando pagamento",
  UpdatePaymentSuccess = "Pagamento atualizado",
  UpdatePaymentError = "Erro ao atualizar pagamento",
  DeletePaymentInit = "Excluindo pagamento",
  DeletePaymentSuccess = "Pagamento excluído",
  DeletePaymentError = "Erro ao excluir pagamento",
  RetrievePaymentsInit = "Obtendo pagamentos",
  RetrievePaymentsSuccess = "Pagamentos obtidos",
  RetrievePaymentsError = "Erro ao obter pagamentos",
  RetrievePaymentsEmpty = "Nenhum pagamento encontrado",
  CancelPaymentInit = "Cancelando pagamento",
  CancelPaymentSuccess = "Pagamento cancelado",
  CancelPaymentError = "Erro ao cancelar pagamento",

  // Payment methods
  RetrievePaymentMethodsInit = "Obtendo métodos de pagamento",
  RetrievePaymentMethodsSuccess = "Métodos de pagamento obtidos",
  RetrievePaymentMethodsError = "Erro ao obter métodos de pagamento",
  RetrievePaymentMethodsEmpty = "Nenhum método de pagamento encontrado",

  // Delivery places
  CreateDeliveryPlaceInit = "Criando local de entrega",
  CreateDeliveryPlaceSuccess = "Local de entrega criado",
  CreateDeliveryPlaceError = "Erro ao criar local de entrega",
  RetrieveDeliveryPlaceInit = "Obtendo local de entrega",
  RetrieveDeliveryPlaceSuccess = "Local de entrega obtido",
  RetrieveDeliveryPlaceError = "Erro ao obter local de entrega",
  UpdateDeliveryPlaceInit = "Atualizando local de entrega",
  UpdateDeliveryPlaceSuccess = "Local de entrega atualizado",
  UpdateDeliveryPlaceError = "Erro ao atualizar local de entrega",
  DeleteDeliveryPlaceInit = "Excluindo local de entrega",
  DeleteDeliveryPlaceSuccess = "Local de entrega excluído",
  DeleteDeliveryPlaceError = "Erro ao excluir local de entrega",
  RetrieveDeliveryPlacesInit = "Obtendo locais de entrega",
  RetrieveDeliveryPlacesSuccess = "Locais de entrega obtidos",
  RetrieveDeliveryPlacesError = "Erro ao obter locais de entrega",
  RetrieveDeliveryPlacesEmpty = "Nenhum local de entrega encontrado",
  ConfirmDeleteDeliveryPlace = "Você tem certeza que deseja excluir este local de entrega?",

  // Addresses
  RetrieveAddressInit = "Obtendo endereço",
  RetrieveAddressSuccess = "Endereço obtido",
  RetrieveAddressError = "Erro ao obter endereço",
  RetrieveAddressEmpty = "Nenhum endereço encontrado",

  // Locations
  RetrieveLocationInit = "Obtendo localização",
  RetrieveLocationSuccess = "Localização obtida",
  RetrieveLocationError = "Erro ao obter localização",
  RetrieveLocationEmpty = "Nenhuma localização encontrada",
}
