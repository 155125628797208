<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span *ngIf="isNewProduct" class="lable3">novo</span>
      <span *ngIf="product.is_highlighted" class="lable4">em destaque</span>
    </div>
    <div class="front">
      <a (click)="onProductClick(product.id)">
        <img
          [defaultImage]="
            ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
          "
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0]?.url"
          class="img-fluid lazy-loading"
          alt="{{ product.images[0]?.alt_text }}"
        />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a (click)="onProductClick(product.id)">
        <img
          [src]="ImageSrc ? ImageSrc : product.images[1].url"
          class="img-fluid lazy-loading"
          alt="{{ product.images[1].alt_text }}"
        />
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc === image.url"
        *ngFor="let image of product.images"
      >
        <a
          href="javascript:void(0)"
          (mouseover)="ChangeVariantsImage(image.url)"
        >
          <img [lazyLoad]="image.url" />
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a
        href="javascript:void(0)"
        title="Adicionar ao carrinho"
        (click)="CartModal.openModal(product)"
        *ngIf="cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Adicionar ao carrinho"
        (click)="addToBasket(product)"
        *ngIf="!cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Adicionar à lista de desejos"
        (click)="addToWishlist(product)"
      >
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Visualizar"
        (click)="QuickView.openModal()"
      >
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        title="Comparar"
        (click)="addToCompare(product)"
      >
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
      <a (click)="onProductClick(product.id)">
        <h6>{{ product.product_name }}</h6>
      </a>
      <p>{{ product.product_specs }}</p>
      <h4>
        {{ currencyService.toLocalCurrency(product.unit_price) }}
      </h4>
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product"></app-quick-view>
<app-cart-modal #cartModal *ngIf="cartModal"></app-cart-modal>
