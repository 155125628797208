import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  currencies = [
    {
      name: "Real",
      currency: "BRL",
      symbol: "R$",
      price: 1,
    },
    {
      name: "Peso argentino",
      currency: "ARS",
      symbol: "$",
      price: 38.68,
    },
    {
      name: "Peso uruguaio",
      currency: "UYU",
      symbol: "$U",
      price: 7.59,
    },
  ];
  config = null;

  private defaultCurrency = "BRL";

  constructor() {
    this.selectCurrency(this.defaultCurrency);
  }

  selectCurrency(currency: string): boolean {
    const config = this.currencies.find((val) => val.currency === currency);
    if (config) {
      this.config = config;
      return true;
    }
    return false;
  }

  convert(value, fromCurrency: string, toCurrency: string): number {
    value = parseFloat(value);
    if (isNaN(value)) {
      return undefined;
    }

    const fromCurrencyConfig = this.currencies.find(
      (currency) => currency.currency === fromCurrency
    );

    const toCurrencyConfig = this.currencies.find(
      (currency) => currency.currency === toCurrency
    );

    if (!fromCurrencyConfig || !toCurrencyConfig) {
      return undefined;
    }

    const conversionRate = fromCurrencyConfig.price / toCurrencyConfig.price;
    const convertedValue = value * conversionRate;
    return convertedValue;
  }

  toLocalCurrency(value, fromCurrency = "BRL"): string {
    const convertedValue = this.convert(
      value,
      fromCurrency,
      this.config.currency
    );
    return `${this.config.symbol} ${convertedValue}`;
  }
}
