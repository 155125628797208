import { Component, forwardRef, Injector, Input, OnInit } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormGroupName,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { DeliveryPlace } from "../../interfaces/delivery-place.interface";

@Component({
  selector: "app-delivery-place-form",
  templateUrl: "./delivery-place-form.component.html",
  styleUrls: ["./delivery-place-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeliveryPlaceFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DeliveryPlaceFormComponent),
      multi: true,
    },
  ],
})
export class DeliveryPlaceFormComponent
  implements OnInit, ControlValueAccessor
{
  @Input() isLarge = false;
  @Input() showError = false;

  form: FormGroup;

  constructor(private injector: Injector) {}

  get value(): DeliveryPlace {
    return this.form.value;
  }

  set value(value: DeliveryPlace) {
    this.form.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  ngOnInit(): void {
    const formGroupDirective = this.injector.get(FormGroupDirective);

    try {
      const formGroupName = this.injector.get(FormGroupName);
      this.form = formGroupDirective.getFormGroup(formGroupName);
    } catch (error) {
      this.form = formGroupDirective.form;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  validate(_: FormControl): ValidationErrors {
    return this.form.valid ? null : { contact: { valid: false } };
  }

  isFieldRequired(fieldName: string): boolean {
    const ctrl = this.form.get(fieldName);
    return ctrl && ctrl.hasValidator(Validators.required);
  }
}
