<form [formGroup]="form">
  <div class="form-group row" [class.form-group-lg]="isLarge">
    <div class="col-md-3">
      <label for="contact-phone" [class.form-label-lg]="isLarge">
        Telefone
        <span *ngIf="isFieldRequired('phone')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        formControlName="phone"
        placeholder="Digite o seu telefone"
        mask="(00) 0000-0000||(00) 00000-0000"
        id="contact-phone"
      />
      <error-display
        [errors]="form.get('phone').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="col-md-3">
      <label for="contact-mobile-phone" [class.form-label-lg]="isLarge">
        Telefone celular
        <span *ngIf="isFieldRequired('mobile_phone')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        formControlName="mobile_phone"
        placeholder="Digite o seu celular"
        mask="(00) 0000-0000||(00) 00000-0000"
        id="contact-mobile-phone"
      />
      <error-display
        [errors]="form.get('mobile_phone').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="col-md-6">
      <label for="contact-email" [class.form-label-lg]="isLarge">
        E-mail {{ isAltEmail ? "alternativo" : "" }}
        <span *ngIf="isFieldRequired('email')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        [placeholder]="
          'Digite o seu e-mail' + (isAltEmail ? ' alternativo' : '')
        "
        class="form-control"
        type="text"
        formControlName="email"
        id="contact-email"
      />
      <error-display
        [errors]="form.get('email').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div *ngIf="showWebsite" class="col-md-6">
      <label for="contact-website" [class.form-label-lg]="isLarge">
        Website
        <span *ngIf="isFieldRequired('website')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        placeholder="Digite o seu site"
        formControlName="website"
        id="contact-website"
      />
      <error-display
        [errors]="form.get('website').errors"
        [showError]="showError"
      ></error-display>
    </div>
  </div>
</form>
