import { Component, Input } from "@angular/core";
import { InventoryProduct } from "src/app/shared/interfaces/inventory-product.interface";
import { CurrencyService } from "src/app/shared/services/sales/currency.service";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent {
  @Input() title: "Produtos";
  @Input() products: InventoryProduct[] = [];

  sliderConfig = {
    items: 1,
    loop: true,
    nav: true,
    dots: false,
    navContainerClass: "owl-nav",
    navClass: ["owl-prev", "owl-next"],
    navText: [
      '<i class="ti-angle-left"></i>',
      '<i class="ti-angle-right"></i>',
    ],
  };

  constructor(public currencyService: CurrencyService) {}
}
