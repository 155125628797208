<form [formGroup]="form">
  <div class="form-group row" [class.form-group-lg]="isLarge">
    <div class="col-md-3">
      <label for="delivery-place-name" [class.form-label-lg]="isLarge">
        Nome do local
        <span *ngIf="isFieldRequired('place_name')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        formControlName="place_name"
        placeholder="Nome do local"
        id="delivery-place-name"
      />
      <error-display
        [errors]="form.get('place_name').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="col-md-3">
      <label for="delivery-place-responsible" [class.form-label-lg]="isLarge">
        Nome do responsável
        <span *ngIf="isFieldRequired('responsible')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        formControlName="responsible"
        placeholder="Nome do responsável"
        id="delivery-place-responsible"
      />
      <error-display
        [errors]="form.get('responsible').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="col-md-6">
      <label for="delivery-place-comments" [class.form-label-lg]="isLarge">
        Comentários
        <span *ngIf="isFieldRequired('comments')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        class="form-control"
        type="text"
        formControlName="comments"
        placeholder="Comentários"
        id="delivery-place-comments"
      />
      <error-display
        [errors]="form.get('comments').errors"
        [showError]="showError"
      ></error-display>
    </div>
  </div>

  <app-contact-form
    [isLarge]="isLarge"
    [isAltEmail]="false"
    [showWebsite]="false"
    [showError]="showError"
    formGroupName="contact"
  ></app-contact-form>

  <app-address-form
    [isLarge]="isLarge"
    [showError]="showError"
    formGroupName="address"
  ></app-address-form>  
</form>
