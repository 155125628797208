<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="sliderConfig" class="offer-slider">
    <ng-container *ngFor="let product of products | slice : 0 : 3">
      <ng-template carouselSlide>
        <div>
          <div
            class="d-flex align-items-center"
            *ngFor="let product of products | slice : 0 : 3"
          >
            <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
              <img
                class="img-fluid w-auto"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.images[0]?.url"
                alt=""
              />
            </a>
            <div class="media-body align-self-center">
              <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
              <a
                [routerLink]="['/shop/collection/infinitescroll/', product.id]"
              >
                <h6>{{ product.product_name }}</h6>
              </a>
              <h4>
                {{ currencyService.toLocalCurrency(product.unit_price) }}
              </h4>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div>
          <div
            class="d-flex align-items-center"
            *ngFor="let product of products | slice : 3 : 6"
          >
            <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
              <img
                class="img-fluid w-auto"
                [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="product.images[0]?.url"
                alt=""
              />
            </a>
            <div class="media-body align-self-center">
              <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
              <a
                [routerLink]="['/shop/collection/infinitescroll/', product.id]"
              >
                <h6>{{ product.product_name }}</h6>
              </a>
              <h4>
                {{ currencyService.toLocalCurrency(product.unit_price) }}
              </h4>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
