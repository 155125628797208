import { Component, Input } from "@angular/core";

@Component({
  selector: "error-display",
  templateUrl: "./error-display.component.html",
  styleUrls: ["./error-display.component.scss"],
})
export class ErrorDisplayComponent {
  private defaultErrorMap = [
    ["required", "Este campo é obrigatório"],
    ["pattern", "Padrão inválido"],
    ["mask", "Valor inválido"],
    ["email", "E-mail inválido"],
    ["minlength", "Tamanho inválido"],
    ["areEqual", "Valores precisam ser iguais"],
    ["notFound", "Não encontrado"],
  ];

  @Input() errorMap = [];
  @Input() errors = null;
  @Input() showError = false;
  @Input() separator = "\n";
  @Input() hidden = false;
  @Input() class = null;

  formatError(errors): string {
    const msg = errors.message;
    if (msg) {
      if (Array.isArray(msg)) {
        return msg.join(this.separator);
      }
      return msg;
    }

    const errorArr = [];
    const errorMaps = [this.errorMap, this.defaultErrorMap];

    for (const errorMap of errorMaps) {
      for (const [id, msg] of errorMap) {
        if (Object.keys(errors).includes(id)) {
          errorArr.push(msg)
        }
      }
    }
    if (errorArr.length) {
      return errorArr.join(this.separator);
    }

    return "Valor inválido";
  }
}
