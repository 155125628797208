<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li *ngIf="!isLoggedIn">Bem-vindo ao Mr. Easy Auto!</li>
              <li *ngIf="isLoggedIn">Bem-vindo, {{ firstName }}!</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Fale Conosco: (11) 99406-8984 ou (11) 2287-6414</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Lista de Desejos</a>
            </li>
            <li class="onhover-dropdown mobile-account">
              <i class="fa fa-user" aria-hidden="true"></i> Minha Conta
              <ul *ngIf="!isLoggedIn" class="onhover-show-div">
                <li>
                  <a [routerLink]="['/pages/login']">Entrar</a>
                </li>
                <li>
                  <a [routerLink]="['/pages/register']">Registrar</a>
                </li>
                <li>
                  <a [routerLink]="['/pages/account/send-activation-token']">Ativar conta</a>
                </li>
              </ul>
              <ul *ngIf="isLoggedIn" class="onhover-show-div">
                <li>
                  <a [routerLink]="['/pages/dashboard']">Painel de controle</a>
                </li>
                <li>
                  <a [routerLink]="['/pages/dashboard/my-orders']">Meus pedidos</a>
                </li>
                <li>
                  <a (click)="logOut()">Sair</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
