<div class="d-flex flex-wrap justify-content-between">
  <div class="calendar-container mb-3">
    <mat-card class="inline-calendar-card">
      <mat-calendar
        [dateFilter]="dateFilter"
        [selected]="selectedDate"
        (selectedChange)="onSelectDate($event)"
        (viewChanged)="onCalendarViewChange($event)"
        #calendarRef
      >
      </mat-calendar>
    </mat-card>
  </div>
  <div class="schedule-container mb-3">
    <div class="mb-3">
      Selecione uma data no calendário e escolha um horário para prosseguir com
      o agendamento.
    </div>
    <div *ngIf="selectedDate">
      <h5 class="mb-3">
        Horários no dia {{ selectedDate | date : "shortDate" }}
      </h5>
      <mat-chip-list
        [selectable]="true"
        aria-label="Horários disponíveis"
        #chipListRef
      >
        <mat-chip
          *ngFor="let schIntvl of getSchIntvlsDate(selectedDate) || []"
          [selected]="chipRef.selected"
          [selectable]="true"
          (click)="chipRef.toggleSelected(true)"
          (selectionChange)="onChipSelectionChange($event, schIntvl)"
          #chipRef="matChip"
        >
          {{ schIntvl.startsAt | date : "shortTime" }} -
          {{ schIntvl.endsAt | date : "shortTime" }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
