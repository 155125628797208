<form [formGroup]="form">
  <div class="row">
    <div class="form-group col-md-3" [class.form-group-lg]="isLarge">
      <label for="address-postcode" [class.form-label-lg]="isLarge">
        CEP
        <span *ngIf="isFieldRequired('postcode')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        mask="00000-000"
        placeholder="CEP"
        formControlName="postcode"
        id="address-postcode"
      />
      <error-display
        [errors]="form.get('postcode').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-7" [class.form-group-lg]="isLarge">
      <label for="address-city" [class.form-label-lg]="isLarge">
        Cidade
        <span *ngIf="isFieldRequired('city')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        placeholder="Cidade"
        formControlName="city"
        id="address-city"
      />
      <error-display
        [errors]="form.get('city').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-2" [class.form-group-lg]="isLarge">
      <label for="address-state" [class.form-label-lg]="isLarge">
        UF
        <span *ngIf="isFieldRequired('state')">*</span>
      </label>
      <select
        class="form-control"
        [class.form-control-lg]="isLarge"
        formControlName="state"
        id="address-state"
      >
        <option>AC</option>
        <option>AL</option>
        <option>AM</option>
        <option>AP</option>
        <option>BA</option>
        <option>CE</option>
        <option>DF</option>
        <option>ES</option>
        <option>GO</option>
        <option>MA</option>
        <option>MG</option>
        <option>MS</option>
        <option>MT</option>
        <option>PA</option>
        <option>PB</option>
        <option>PE</option>
        <option>PI</option>
        <option>PR</option>
        <option>RJ</option>
        <option>RN</option>
        <option>RO</option>
        <option>RR</option>
        <option>RS</option>
        <option>SC</option>
        <option>SE</option>
        <option>SP</option>
        <option>TO</option>
      </select>
      <error-display
        [errors]="form.get('state').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-3" [class.form-group-lg]="isLarge">
      <label for="address-district" [class.form-label-lg]="isLarge">
        Bairro
        <span *ngIf="isFieldRequired('district')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        placeholder="Bairro"
        formControlName="district"
        id="address-district"
      />
      <error-display
        [errors]="form.get('district').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-7" [class.form-group-lg]="isLarge">
      <label for="address-street" [class.form-label-lg]="isLarge">
        Logradouro
        <span *ngIf="isFieldRequired('street')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        placeholder="Logradouro"
        formControlName="street"
        id="address-street"
      />
      <error-display
        [errors]="form.get('street').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-2" [class.form-group-lg]="isLarge">
      <label for="address-number" [class.form-label-lg]="isLarge">
        Número
        <span *ngIf="isFieldRequired('number')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        placeholder="Número"
        formControlName="number"
        id="address-number"
      />
      <error-display
        [errors]="form.get('number').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-6" [class.form-group-lg]="isLarge">
      <label for="address-complement" [class.form-label-lg]="isLarge">
        Complemento
        <span *ngIf="isFieldRequired('complement')">*</span>
      </label>
      <input
        [class.form-control-lg]="isLarge"
        type="text"
        class="form-control"
        placeholder="Complemento"
        formControlName="complement"
        id="address-complement"
      />
      <error-display
        [errors]="form.get('complement').errors"
        [showError]="showError"
      ></error-display>
    </div>

    <div class="form-group col-md-6" [class.form-group-lg]="isLarge">
      <label for="address-location" [class.form-label-lg]="isLarge">
        Coordenadas
        <span *ngIf="isFieldRequired('location')">*</span>
      </label>
      <app-location-input
        formControlName="location"
        id="address-location"
      ></app-location-input>
      <error-display
        [errors]="form.get('location').errors"
        [showError]="showError"
      ></error-display>
    </div>
  </div>
</form>
