<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="" />
        <i class="ti-search"></i>
      </div>
      <div
        id="search-overlay"
        class="search-overlay"
        [ngStyle]="{ display: search ? 'block' : 'none' }"
      >
        <div>
          <span class="closebtn" title="Close Overlay" (click)="searchToggle()"
            >x</span
          >
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Search a Product"
                      />
                    </div>
                    <button type="submit" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="" />
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ items.length }}</span>
      <ul class="show-div shopping-cart" *ngIf="!items.length">
        <h5>Seu carrinho está vazio.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf="items.length">
        <li *ngFor="let item of items">
          <div class="media">
            <div class="media-body">
              <a [routerLink]="['/']">
                <h4>{{ item.inventory_product.product_name }}</h4>
              </a>
              <h4>
                <span>
                  {{ item.quantity }} x {{ item.unit_price  * currencyConfig.price | currency:currencyConfig.currency }}
                </span>
              </h4>
            </div>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>
              subtotal:
              <span>{{ basket.price_summary.subtotal * currencyConfig.price | currency:currencyConfig.currency }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a (click)="goToCart()" class="view-cart">Ver carrinho</a>
            <a (click)="goToCheckout()" class="checkout">Finalizar compra</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
