import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BasketService } from "../../services/sales/basket.service";
import { CurrencyService } from "../../services/sales/currency.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
  search = false;

  languages = [
    {
      name: "Português",
      code: "pt-BR",
    },
    {
      name: "Espanhol",
      code: "es",
    },
  ];

  get basket() {
    return this.basketService.basket;
  }

  get items() {
    return this.basket?.items || [];
  }

  get currencies() {
    return this.currencyService.currencies;
  }

  get currencyConfig() {
    return this.currencyService.config;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private translate: TranslateService,
    private basketService: BasketService,
    private currencyService: CurrencyService
  ) {}

  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  changeCurrency(currency: string) {
    this.currencyService.selectCurrency(currency);
  }

  goToCart(): void {
    this.router.navigate(["/shop/cart"]);
  }

  goToCheckout(): void {
    const itemIds = this.items.map((item) => item.id);
    const queryParams = { item: itemIds };
    this.router.navigate(["/shop/checkout"], { queryParams: queryParams });
  }
}
