import { Component, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  validateLat,
  validateLng,
} from "src/app/shared/validators/location.validator";
import { GeoLocation } from "../../interfaces/geolocation.interface";
import { requiredTogether } from "../../validators/form-group.validator";

@Component({
  selector: "app-location-input",
  templateUrl: "./location-input.component.html",
  styleUrls: ["./location-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationInputComponent),
      multi: true,
    },
  ],
})
export class LocationInputComponent implements ControlValueAccessor {
  @Input() isLarge = false;
  @Input() showError = false;

  form: FormGroup = null;
  
  private isNull = true;

  constructor() {
    this.form = new FormGroup({
      type: new FormControl("Point"),
      coordinates: new FormArray(
        [new FormControl("", validateLng), new FormControl("", validateLat)],
        requiredTogether(Validators.required, ["0", "1"])
      ),
    });
  }

  get value(): GeoLocation {
    if (this.isNull) {
      return null;
    }
    return this.form.value;
  }

  set value(value: GeoLocation) {
    if (value != null) {
      this.form.patchValue(value);
      this.isNull = false;
    } else {
      this.form.reset();
      this.isNull = true;
    }

    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  writeValue(value): void {
    this.value = value;
  }

  validate(): ValidationErrors {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  onInputChange(): void {
    this.onChange(this.value);
  }

  onBlur(): void {
    this.onTouched();
  }
}
