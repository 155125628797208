import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxMaskApplierService } from "ngx-mask";
import { catchError, Observable, tap, throwError } from "rxjs";
import { EnumAlert } from "../../enum/alert.enum";
import { Address } from "../../interfaces/address.interface";
import { atLeastOne } from "../../validators/form-group.validator";
import { SettingsService } from "../settings.service";
import { UtilService } from "../util.service";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  readonly stateChoices = [
    ["AC", "AC"],
    ["AL", "AL"],
    ["AP", "AP"],
    ["AM", "AM"],
    ["BA", "BA"],
    ["CE", "CE"],
    ["DF", "DF"],
    ["ES", "ES"],
    ["GO", "GO"],
    ["MA", "MA"],
    ["MT", "MT"],
    ["MS", "MS"],
    ["MG", "MG"],
    ["PA", "PA"],
    ["PB", "PB"],
    ["PR", "PR"],
    ["PE", "PE"],
    ["PI", "PI"],
    ["RJ", "RJ"],
    ["RN", "RN"],
    ["RS", "RS"],
    ["RO", "RO"],
    ["RR", "RR"],
    ["SC", "SC"],
    ["SP", "SP"],
    ["SE", "SE"],
    ["TO", "TO"],
  ];

  constructor(
    private http: HttpClient,
    private maskService: NgxMaskApplierService,
    private settingsService: SettingsService,
    private utilService: UtilService
  ) {}

  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/";
  }

  buildAddressForm(): FormGroup {
    return new FormGroup(
      {
        street: new FormControl("", [Validators.required]),
        number: new FormControl("", [Validators.required]),
        complement: new FormControl(""),
        district: new FormControl("", [Validators.required]),
        city: new FormControl("", [Validators.required]),
        state: new FormControl("", [Validators.required]),
        postcode: new FormControl("", [Validators.required]),
        location: new FormControl(""),
      },
      {
        validators: [atLeastOne(Validators.required, ["number", "location"])],
      }
    );
  }

  formatAddress(address: Address): string {
    const filterJoin = (array: string[], sep: string) =>
      array.filter((val) => val != null && val !== "").join(sep);

    const streetNumberCompl = filterJoin(
      [address.street, address.number, address.complement],
      ", "
    );
    const streetNumberComplDistr = filterJoin(
      [streetNumberCompl, address.district],
      ". "
    );
    const cityState = filterJoin([address.city, address.state], " - ");

    const postcodePattern = "00000-000";
    const postcode = this.maskService.applyMask(
      address.postcode || "",
      postcodePattern
    );

    return filterJoin([streetNumberComplDistr, cityState, postcode], ". ");
  }

  getLocationByAddress(
    address: string,
    providers?: string[]
  ): Observable<Address> {
    console.info(EnumAlert.RetrieveLocationInit);

    const params = this.utilService.createHttpParams({
      address: address,
      providers: providers,
    });
    return this.http
      .get<Address>(
        this.apiUrl.concat(
          `v1/enterprise/addresses/services/geocoder/address/`
        ),
        { params: params }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveLocationError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveLocationSuccess);
          console.debug(val);
        })
      );
  }

  getLocationByCoords(
    lat: number,
    lng: number,
    providers?: string[]
  ): Observable<Address> {
    console.info(EnumAlert.RetrieveLocationInit);

    const params = this.utilService.createHttpParams({
      lat: lat,
      lng: lng,
      providers: providers,
    });
    return this.http
      .get<Address>(
        this.apiUrl.concat(
          `v1/enterprise/addresses/services/geocoder/coordinates/`
        ),
        { params: params }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveLocationError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveLocationSuccess);
          console.debug(val);
        })
      );
  }

  getLocationByPostcode(
    postcode: string,
    providers?: string[]
  ): Observable<Address> {
    console.info(EnumAlert.RetrieveLocationInit);

    const params = this.utilService.createHttpParams({
      postcode: postcode,
      providers: providers,
    });
    return this.http
      .get<Address>(
        this.apiUrl.concat(
          `v1/enterprise/addresses/services/geocoder/postcode/`
        ),
        { params: params }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveLocationError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveLocationSuccess);
          console.debug(val);
        })
      );
  }
}
