import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import * as moment from "moment";
import { EnumAlert } from "src/app/shared/enum/alert.enum";
import { InventoryProduct } from "src/app/shared/interfaces/inventory-product.interface";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { BasketService } from "src/app/shared/services/sales/basket.service";
import { CurrencyService } from "src/app/shared/services/sales/currency.service";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";

@Component({
  selector: "app-product-box-one",
  templateUrl: "./product-box-one.component.html",
  styleUrls: ["./product-box-one.component.scss"],
})
export class ProductBoxOneComponent implements OnInit {
  @Input() product: InventoryProduct;
  @Input() thumbnail = false;
  @Input() onHowerChangeImage = false;
  @Input() cartModal = false;
  @Input() loader = false;

  @Output() productClick = new EventEmitter<number>();

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string;

  constructor(
    public currencyService: CurrencyService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private basketService: BasketService
  ) {}

  get isNewProduct(): boolean {
    const product = this.product;
    if (product == null || product.created_at == null) {
      return false;
    }
    const createdAt = moment(product.created_at);
    return createdAt.diff(moment.now(), "days") < 10;
  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        });
      }
    });
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToBasket(product: InventoryProduct): void {
    const isLoggedIn = this.authService.isLoggedIn;
    const basketId = this.basketService.basketId;

    if (!isLoggedIn || basketId == null) {
      const url = this.router.url;
      this.router.navigate(["/pages/login"], {
        queryParams: { returnUrl: url },
      });
      return;
    }

    if (this.basketService.navigateToProdCustomPage(product)) {
      return;
    }

    console.info(EnumAlert.CreateBasketItemInit);
    const data = {
      inventory_product_id: product.id,
      quantity: 1,
    };

    this.basketService.createBasketItem(basketId, data).subscribe({
      next: () => {
        this.snackBar.open(EnumAlert.CreateBasketItemSuccess, "Ok", {
          duration: 5000,
        });
      },
      error: () => {
        this.snackBar.open(EnumAlert.CreateBasketItemError, "Ok", {
          duration: 5000,
        });
      },
    });
  }

  addToWishlist(product: any) {
    // this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    // this.productService.addToCompare(product);
  }

  onProductClick(productId: number): void {
    if (productId != null) {
      this.productClick.emit(this.product.id);
    }
  }
}
