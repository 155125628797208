import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { lastValueFrom } from "rxjs";
import { EnumAlert } from "src/app/shared/enum/alert.enum";
import { BasketItem } from "src/app/shared/interfaces/basket.interface";
import { InventoryProduct } from "src/app/shared/interfaces/inventory-product.interface";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { BasketService } from "src/app/shared/services/sales/basket.service";
import { CurrencyService } from "src/app/shared/services/sales/currency.service";
import { ProductService } from "src/app/shared/services/sales/inventory-product.service";

@Component({
  selector: "app-cart-modal",
  templateUrl: "./cart-modal.component.html",
  styleUrls: ["./cart-modal.component.scss"],
})
export class CartModalComponent implements OnDestroy {
  @ViewChild("cartModal", { static: false }) CartModal: TemplateRef<any>;

  closeResult: string;
  modalOpen = false;
  product: InventoryProduct = null;
  basketItem: BasketItem = null;
  relatedProducts: InventoryProduct[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public currencyService: CurrencyService,
    private router: Router,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    private authService: AuthService,
    private productService: ProductService,
    private basketService: BasketService
  ) {}

  ngOnDestroy() {
    this.product = null;
    this.closeModal();
  }

  async openModal(product, quantity = 1): Promise<boolean> {
    this.product = product;
    this.relatedProducts.length = 0;

    const isLoggedIn = this.authService.isLoggedIn;
    const basketId = this.basketService.basketId;

    if (this.basketService.navigateToProdCustomPage(product)) {
      return false;
    }

    if (!isLoggedIn || basketId == null) {
      const url = this.router.url;
      this.router.navigate(["/pages/login"], {
        queryParams: { returnUrl: url },
      });
      return false;
    }

    console.info(EnumAlert.CreateBasketItemInit);
    const data = {
      inventory_product_id: product.id,
      quantity: quantity,
    };
    const addToBasket$ = this.basketService.createBasketItem(basketId, data);
    try {
      this.basketItem = await lastValueFrom(addToBasket$);
      console.info(EnumAlert.CreateBasketItemSuccess);
    } catch (err) {
      console.error(EnumAlert.CreateBasketItemError);
      console.error(err);
      this.snackBar.open(EnumAlert.CreateBasketItemError, "Ok", {
        duration: 5000,
      });
      return false;
    }

    console.info(EnumAlert.RetrieveProductsInit);
    const filter = { bought_with: product.id, page_size: 4 };
    const fetchRelated$ = this.productService.getInventoryProducts(filter);
    try {
      const relatedProducts = await lastValueFrom(fetchRelated$);
      if (relatedProducts.count) {
        console.info(EnumAlert.RetrieveProductsSuccess);
        relatedProducts.results.forEach((relatedProduct) =>
          this.relatedProducts.push(relatedProduct)
        );
      } else {
        console.info(EnumAlert.RetrieveProductsEmpty);
      }
    } catch (err) {
      console.error(EnumAlert.RetrieveProductsError);
      console.error(err);
    }

    this.modalOpen = true;

    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.CartModal, {
          size: "lg",
          ariaLabelledBy: "Cart-Modal",
          centered: true,
          windowClass: "theme-modal cart-modal CartModal",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }

    return true;
  }

  closeModal() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
