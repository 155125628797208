import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ExpansionPanelService } from "../../services/expansion-panel.service";

let nextGroup = 0;
let nextSubgroup = 0;
let nextName = 0;
let nextId = 0;

@Component({
  selector: "app-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"],
})
export class ExpansionPanelComponent implements OnInit, OnDestroy {
  @Input() group = `exp-panel-group-${nextGroup++}`;
  @Input() subgroup = `exp-panel-subgroup-${nextSubgroup++}`;
  @Input() name = `exp-panel-name-${nextName++}`;
  @Input() id = `exp-panel-id-${nextId++}`;

  isOpen = false;

  private commandSub: Subscription;

  constructor(private expPanelService: ExpansionPanelService) {}

  ngOnInit() {
    this.commandSub = this.expPanelService.command$.subscribe((val) => {
      const selector = val.selector;

      if (selector.type === "unit" && selector.id !== this.id) return;
      if (selector.type === "group") {
        if (selector.group && selector.group !== this.group) return;
        if (selector.subgroup && selector.subgroup !== this.subgroup) return;
        if (selector.name && selector.name !== this.name) return;
      }

      if (val.action === "open") {
        this.isOpen = true;
      } else if (val.action === "close") {
        this.isOpen = false;
      } else if (val.action === "toggle") {
        this.isOpen != this.isOpen;
      }
    });
  }

  ngOnDestroy() {
    this.commandSub.unsubscribe();
  }

  open() {
    this.expPanelService.open({
      type: "unit",
      id: this.id,
    });
  }

  openSiblings() {
    this.expPanelService.open({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
      name: this.name,
    });
  }

  openGroup() {
    this.expPanelService.open({
      type: "group",
      group: this.group,
    });
  }

  openSubgroup() {
    this.expPanelService.open({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
    });
  }

  close() {
    this.expPanelService.close({
      type: "unit",
      id: this.id,
    });
  }

  closeSiblings() {
    this.expPanelService.close({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
      name: this.name,
    });
  }

  closeGroup() {
    this.expPanelService.close({
      type: "group",
      group: this.group,
    });
  }

  closeSubgroup() {
    this.expPanelService.close({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
    });
  }

  toggle() {
    this.expPanelService.toggle({
      type: "unit",
      id: this.id,
    });
  }

  toggleSiblings() {
    this.expPanelService.toggle({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
      name: this.name,
    });
  }

  toggleGroup() {
    this.expPanelService.toggle({
      type: "group",
      group: this.group,
    });
  }

  toggleSubgroup() {
    this.expPanelService.toggle({
      type: "group",
      group: this.group,
      subgroup: this.subgroup,
    });
  }
}
