import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const atLeastOne =
  (
    validator: ValidatorFn,
    controls: string[] = null,
    errorName = "atLeastOne"
  ) =>
  (group: FormGroup): ValidationErrors => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }
    const invalid = controls.some((k) => !validator(group.controls[k]));
    return invalid ? null : { [errorName]: true };
  };

export const requiredTogether =
  (
    validator: ValidatorFn,
    controls: string[] = null,
    errorName = "requiredTogether"
  ) =>
  (group: FormGroup): ValidationErrors => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const invalid = controls.some((k) => validator(group.controls[k]));
    if (invalid) {
      return { [errorName]: true };
    }

    return null;
  };

export const mustDiffer =
  (controls: string[] = null, errorName = "mustDiffer", ignoreNull = true) =>
  (group: FormGroup): ValidationErrors => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const seenValues = new Set<any>();

    for (const k of controls) {
      const control = group.get(k);
      if (seenValues.has(control.value)) {
        return { [errorName]: true };
      }
      if (control.value != null || ignoreNull) {
        seenValues.add(control.value);
      }
    }

    return null;
  };
