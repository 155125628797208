<form [formGroup]="form">
  <div formArrayName="coordinates" class="input-group">
    <input
      [class.form-control-lg]="isLarge"
      (input)="onInputChange()"
      (blur)="onBlur()"
      type="text"
      class="form-control"
      placeholder="Latitude"
      formControlName="1"
      id="location-lat"
    />
    <input
      [class.form-control-lg]="isLarge"
      (input)="onInputChange()"
      type="text"
      class="form-control"
      placeholder="Longitude"
      formControlName="0"
      id="location-lng"
    />
  </div>
</form>
