import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { QRCodeModule } from "angularx-qrcode";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { BarRatingModule } from "ngx-bar-rating";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

// Angular Material
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSnackBarModule } from "@angular/material/snack-bar";

// Header and Footer Components
import { FooterFourComponent } from "./footer/footer-four/footer-four.component";
import { FooterOneComponent } from "./footer/footer-one/footer-one.component";
import { FooterThreeComponent } from "./footer/footer-three/footer-three.component";
import { FooterTwoComponent } from "./footer/footer-two/footer-two.component";
import { HeaderFourComponent } from "./header/header-four/header-four.component";
import { HeaderOneComponent } from "./header/header-one/header-one.component";
import { HeaderThreeComponent } from "./header/header-three/header-three.component";
import { HeaderTwoComponent } from "./header/header-two/header-two.component";

// Components
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { DeliveryPlaceFormComponent } from "./components/delivery-place-form/delivery-place-form.component";
import { ErrorDisplayComponent } from "./components/error-display/error-display.component";
import { ExpansionPanelComponent } from "./components/expansion-panel/expansion-panel.component";
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { LocationInputComponent } from "./components/location-input/location-input.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ProductBoxFiveComponent } from "./components/product/product-box-five/product-box-five.component";
import { ProductBoxFourComponent } from "./components/product/product-box-four/product-box-four.component";
import { ProductBoxOneComponent } from "./components/product/product-box-one/product-box-one.component";
import { ProductBoxThreeComponent } from "./components/product/product-box-three/product-box-three.component";
import { ProductBoxTwoComponent } from "./components/product/product-box-two/product-box-two.component";
import { ProductBoxVerticalSliderComponent } from "./components/product/product-box-vertical-slider/product-box-vertical-slider.component";
import { ProductBoxVerticalComponent } from "./components/product/product-box-vertical/product-box-vertical.component";
import { ScheduleComponent } from "./components/schedule/schedule.component";
import { SettingsComponent } from "./components/settings/settings.component";

// Modals Components
import { AgeVerificationComponent } from "./components/modal/age-verification/age-verification.component";
import { CartModalComponent } from "./components/modal/cart-modal/cart-modal.component";
import { CartVariationComponent } from "./components/modal/cart-variation/cart-variation.component";
import { NewsletterComponent } from "./components/modal/newsletter/newsletter.component";
import { QuickViewComponent } from "./components/modal/quick-view/quick-view.component";
import { SizeModalComponent } from "./components/modal/size-modal/size-modal.component";
import { VideoModalComponent } from "./components/modal/video-modal/video-modal.component";

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from "./components/skeleton/skeleton-product-box/skeleton-product-box.component";

// Layout Box
import { LayoutBoxComponent } from "./components/layout-box/layout-box.component";

// Tap To Top
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";

// Directives
import { FitTextDirective } from "./directives/fittext.directive";

// Pipes
import { DiscountPipe } from "./pipes/discount.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";

// Providers
import { PtBrMatPaginatorIntl } from "./providers/paginator.provider";

@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    LocationInputComponent,
    AddressFormComponent,
    ContactFormComponent,
    DeliveryPlaceFormComponent,
    ScheduleComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    ExpansionPanelComponent,
    ErrorDisplayComponent,
    FitTextDirective,
    TruncatePipe,
    DiscountPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSnackBarModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TranslateModule,
    QRCodeModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSnackBarModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TranslateModule,
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    LocationInputComponent,
    AddressFormComponent,
    ContactFormComponent,
    DeliveryPlaceFormComponent,
    ScheduleComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    ExpansionPanelComponent,
    ErrorDisplayComponent,
    FitTextDirective,
    TruncatePipe,
    DiscountPipe,
    QRCodeModule,
  ],
  providers: [
    provideNgxMask(),
    {
      provide: MatPaginatorIntl,
      useClass: PtBrMatPaginatorIntl,
    },
  ],
})
export class SharedModule {}
