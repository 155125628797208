import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class AuthInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.token;

    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401 && token) {
          return this.handleHttp401Error(request, next);
        } else {
          return throwError(() => err);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  private handleHttp401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.authService.refresh().pipe(
        switchMap((val: any) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(val.token);
          request = this.addToken(request, val.token);
          return next.handle(request);
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter((val) => val != null),
        take(1),
        switchMap((token) => {
          request = this.addToken(request, token);
          return next.handle(request);
        })
      );
    }
  }
}
