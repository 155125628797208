import { registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ToastrModule } from "ngx-toastr";
import { mergeMap, take } from "rxjs";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { ElementsComponent } from "./elements/elements.component";
import { PagesComponent } from "./pages/pages.component";
import { pubSubFactory } from "./shared/pubsub/pubsub.factory";
import { PubSubService } from "./shared/pubsub/pubsub.service";
import { AuthInterceptorService } from "./shared/services/auth/auth_interceptor.service";
import { UserService } from "./shared/services/users/user.service";
import { SettingsService } from "./shared/services/settings.service";
import { ShopComponent } from "./shop/shop.component";

registerLocaleData(ptBr);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function AppLoaderFactory(
  userService: UserService,
  settingsService: SettingsService
) {
  return () => {
    settingsService.setUp();
    return settingsService.settings$.pipe(
      mergeMap(() => {
        userService.setUp();
        return userService.user$;
      }),
      take(1)
    );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: "pt-BR",
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: "BRL",
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppLoaderFactory,
      deps: [UserService, SettingsService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: PubSubService,
      useFactory: pubSubFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
