import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import jwtDecode, { JwtPayload } from "jwt-decode";
import * as moment from "moment";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SettingsService } from "../settings.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly auth$: Observable<boolean>;

  private _authSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
      this._authSubject = new BehaviorSubject<boolean>(this.isLoggedIn);
      this.auth$ = this._authSubject.asObservable();
  }

  get isLoggedIn(): boolean {
    return this.token != null;
  }

  get token(): string | null {
    return localStorage.getItem("auth.accessToken");
  }

  set token(token: string | null) {
    if (token == null) localStorage.removeItem("auth.accessToken");
    else localStorage.setItem("auth.accessToken", token);
  }

  get refreshToken(): string | null {
    return localStorage.getItem("auth.refreshToken");
  }

  set refreshToken(refreshToken: string | null) {
    if (refreshToken == null) localStorage.removeItem("auth.refreshToken");
    else localStorage.setItem("auth.refreshToken", refreshToken);
  }

  get expiresAt(): moment.Moment | null {
    const value = localStorage.getItem("auth.expiresAt");
    if (value == null) return null;
    return moment(JSON.parse(value));
  }

  set expiresAt(expiresAt: moment.Moment | null) {
    if (expiresAt == null) localStorage.removeItem("auth.expiresAt");
    else {
      const value = JSON.stringify(expiresAt.valueOf());
      localStorage.setItem("auth.expiresAt", value);
    }
  }

  private get apiUrl(): string {
    return this.settingsService.apiUrl;
  }

  logIn(data): Observable<any> {
    console.info("Fazendo login.");
    return this.http.post(this.apiUrl.concat("auth/"), data).pipe(
      catchError((err) => {
        console.error("Erro na autentificação.");
        console.error(err);
        if (err.status !== 0) {
          this.logOut();
        }
        return throwError(() => err);
      }),
      tap((val) => {
        this.setSession(val);
        console.info("Login realizado com sucesso.");
      })
    );
  }

  logOut() {
    console.info("Fazendo logout.");
    this.token = null;
    this.refreshToken = null;
    this.expiresAt = null;
    this._authSubject.next(false);
    console.info("Logout realizado com sucesso.");
  }

  refresh() {
    console.info("Atualizando token de autentificação.");
    const data = { refresh: this.refreshToken };
    return this.http.post(this.apiUrl.concat("refresh-token/"), data).pipe(
      catchError((err) => {
        console.error("Erro na atualização do token de autentificação.");
        console.error(err);
        if (err.status !== 0) {
          this.logOut();
        }
        return throwError(() => err);
      }),
      tap((res) => {
        this.setSession(res);
        console.info("Token de autentificação atualizado com sucesso.");
      })
    );
  }

  setSession(data) {
    const access = data.access;
    const refresh = data.refresh;
    const payload = jwtDecode(access) as JwtPayload;

    this.token = access;
    this.refreshToken = refresh;
    this.expiresAt = moment.unix(payload.exp);
    this._authSubject.next(true);
  }
}
