import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  expand,
  mergeMap,
  Observable,
  shareReplay,
  switchMap,
  tap,
  throwError,
  toArray,
} from "rxjs";
import { EnumAlert } from "src/app/shared/enum/alert.enum";
import { PaginatedResponse } from "src/app/shared/interfaces/core.interface";
import {
  ProductCategory,
  ProductCategoryFilter,
} from "src/app/shared/interfaces/product-category.interface";
import { SettingsService } from "src/app/shared/services/settings.service";
import { UtilService } from "src/app/shared/services/util.service";

@Injectable({
  providedIn: "root",
})
export class ProductCategoryService {
  private productCategoryList$: Observable<ProductCategory[]>;
  private refreshTrigger = new BehaviorSubject<void>(undefined);

  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/";
  }

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private utilService: UtilService
  ) {}

  getProductCategory(categoryId: number): Observable<ProductCategory> {
    console.info(EnumAlert.RetrieveProductCategoryInit);
    return this.http
      .get<ProductCategory>(
        this.apiUrl.concat(`v1/sales/products/public/categories/${categoryId}/`)
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveProductCategoryError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          console.info(EnumAlert.RetrieveProductCategorySuccess);
          console.debug(val);
        })
      );
  }

  getProductCategories(
    filter?: ProductCategoryFilter
  ): Observable<PaginatedResponse<ProductCategory>> {
    console.info(EnumAlert.RetrieveProductCategoriesInit);
    const params = this.utilService.createHttpParams(filter);
    return this.http
      .get<PaginatedResponse<ProductCategory>>(
        this.apiUrl.concat(`v1/sales/products/public/categories/`),
        {
          params: params,
        }
      )
      .pipe(
        catchError((err) => {
          console.info(EnumAlert.RetrieveProductCategoriesError);
          console.error(err);
          return throwError(() => err);
        }),
        tap((val) => {
          if (val.count) {
            console.info(EnumAlert.RetrieveProductCategoriesSuccess);
          } else {
            console.info(EnumAlert.RetrieveProductCategoriesEmpty);
          }
          console.debug(val);
        })
      );
  }

  getAllProductCategories(
    filter?: ProductCategoryFilter
  ): Observable<ProductCategory[]> {
    console.info(EnumAlert.RetrieveProductCategoriesInit);
    const params = this.utilService.createHttpParams(filter);
    return this.http
      .get<PaginatedResponse<ProductCategory>>(
        this.apiUrl.concat(`v1/sales/products/public/categories/`),
        {
          params: params,
        }
      )
      .pipe(
        expand(({ next }) => {
          return next
            ? this.http.get<PaginatedResponse<ProductCategory>>(next)
            : EMPTY;
        }),
        mergeMap(({ results }) => results),
        catchError((err) => {
          console.info(EnumAlert.RetrieveProductCategoriesError);
          console.error(err);
          return throwError(() => err);
        }),
        toArray(),
        tap((val) => {
          if (val.length > 0) {
            console.info(EnumAlert.RetrieveProductCategoriesSuccess);
          } else {
            console.info(EnumAlert.RetrieveProductCategoriesEmpty);
          }
          console.debug(val);
        })
      );
  }

  getProductCategoryListReplay(): Observable<ProductCategory[]> {
    if (!this.productCategoryList$) {
      this.productCategoryList$ = this.refreshTrigger.pipe(
        switchMap(() => this.getAllProductCategories()),
        shareReplay(1)
      );
    }
    return this.productCategoryList$;
  }

  refreshProductCategoryList(): void {
    this.refreshTrigger.next();
  }
}
