import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { EnumAlert } from "../../enum/alert.enum";
import { ProductCategory } from "../../interfaces/product-category.interface";
import { ProductCategoryService } from "../../services/sales/product-category.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @Input() selectedCategoryId: number;

  @Output() selectedCategoryIdChange = new EventEmitter<number>();

  categories: ProductCategory[] = [];
  collapse = true;

  private prodCatSub: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private productCategoryService: ProductCategoryService
  ) {}

  ngOnInit(): void {
    this.prodCatSub = this.subscribeToCategories();
  }

  ngOnDestroy(): void {
    if (this.prodCatSub) {
      this.prodCatSub.unsubscribe();
    }
  }

  subscribeToCategories() {
    return this.productCategoryService
      .getProductCategoryListReplay()
      .subscribe({
        next: (val) => {
          this.categories.length = 0;
          val.forEach((category) => this.categories.push(category));
        },
        error: () => {
          this.snackBar.open(EnumAlert.RetrieveProductCategoriesError, "Ok", {
            duration: 5000,
          });
        },
      });
  }

  onCategoryClick(categoryId: number): void {
    this.selectedCategoryIdChange.emit(categoryId);
  }
}
