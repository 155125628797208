import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EnumAlert } from "src/app/shared/enum/alert.enum";
import { InventoryProduct } from "src/app/shared/interfaces/inventory-product.interface";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { BasketService } from "src/app/shared/services/sales/basket.service";
import { CurrencyService } from "src/app/shared/services/sales/currency.service";

@Component({
  selector: "app-quick-view",
  templateUrl: "./quick-view.component.html",
  styleUrls: ["./quick-view.component.scss"],
})
export class QuickViewComponent implements OnDestroy {
  @Input() product: InventoryProduct = null;

  @Output() productChange = new EventEmitter<InventoryProduct>();

  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  closeResult: string;
  quantity = 1;
  modalOpen = false;
  variants: InventoryProduct[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public currencyService: CurrencyService,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private basketService: BasketService,
    private modalService: NgbModal
  ) {}

  get unitPrice(): number {
    return parseFloat(this.product.unit_price);
  }

  get discount(): number {
    return parseFloat(this.product.discount);
  }

  get discountedPrice(): number {
    return this.unitPrice - this.discount;
  }

  ngOnDestroy(): void {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  openModal(): void {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.QuickView, {
          size: "lg",
          ariaLabelledBy: "modal-basic-title",
          centered: true,
          windowClass: "Quickview",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  closeModal() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  increment(): void {
    this.quantity++;
  }

  decrement(): void {
    if (this.quantity > 1) this.quantity--;
  }

  addToBasket(product: InventoryProduct): void {
    const isLoggedIn = this.authService.isLoggedIn;
    const basketId = this.basketService.basketId;

    if (!isLoggedIn || basketId == null) {
      const url = this.router.url;
      this.router.navigate(["/pages/login"], {
        queryParams: { returnUrl: url },
      });
      return;
    }

    if (this.basketService.navigateToProdCustomPage(product)) {
      return;
    }

    console.info(EnumAlert.CreateBasketItemInit);
    const data = {
      inventory_product_id: product.id,
      quantity: this.quantity,
    };

    this.basketService.createBasketItem(basketId, data).subscribe({
      next: () => {
        this.snackBar.open(EnumAlert.CreateBasketItemSuccess, "Ok", {
          duration: 5000,
        });
        this.closeModal();
      },
      error: () => {
        this.snackBar.open(EnumAlert.CreateBasketItemError, "Ok", {
          duration: 5000,
        });
      },
    });
  }
}
