import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import * as fitty from "fitty";
import { Subject, Subscription } from "rxjs";

@Directive({
  selector: "[appFitText]",
})
export class FitTextDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input("appFitText") config: {
    refreshSubject?: Subject<boolean>;
    options?: fitty.FittyOptions;
  } = {};

  private fittyInstance: fitty.FittyInstance | null = null;
  private refreshSubscription: Subscription = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.initFitty();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("appFitText" in changes) {
      this.destroyFitty();
      this.initFitty();
    }
  }

  ngOnDestroy() {
    this.destroyFitty();
  }

  private initFitty() {
    if (!this.fittyInstance) {
      this.fittyInstance = fitty.default(
        this.el.nativeElement,
        this.config.options
      );
      setTimeout(() => this.fittyInstance.fit());
    }

    if (!this.refreshSubscription && this.config.refreshSubject) {
      this.refreshSubscription = this.config.refreshSubject.subscribe((val) => {
        if (this.fittyInstance && val) {
          this.fittyInstance.fit();
        }
      });
    }
  }

  private destroyFitty() {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
      this.fittyInstance = null;
    }
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }
}
