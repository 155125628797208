<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
  <div *ngIf="basketItem" class="modal-body">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="modal-bg addtocart">
            <button
              type="button"
              id="close-cart-modal"
              class="close"
              (click)="modal.dismiss('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="media">
              <a
                [routerLink]="['/shop/collection/infinitescroll/', product.id]"
              >
                <img
                  class="img-fluid pro-img"
                  [src]="product.images[0]?.url"
                  [alt]="product.images[0]?.alt_text"
                />
              </a>
              <div class="media-body align-self-center text-center">
                <a
                  [routerLink]="['/shop/collection/infinitescroll/', product.id]"
                >
                  <h6>
                    <i class="fa fa-check"></i>Item
                    <span>'{{ product.product_name }}'</span>
                    <span> adicionado ao carrinho</span>
                  </h6>
                </a>
                <div class="d-flex buttons">
                  <a
                    [routerLink]="['/shop/cart']"
                    class="view-cart btn btn-solid"
                  >
                    Ir para o carrinho
                  </a>
                  <a
                    [routerLink]="['/shop/checkout']"
                    [queryParams]="{item: basketItem.id}"
                    class="checkout btn btn-solid"
                  >
                    Finalizar compra
                  </a>
                  <a
                    (click)="closeModal()"
                    class="continue btn btn-solid"
                  >
                    Continuar comprando
                  </a>
                </div>
              </div>
            </div>

            <div class="product-section" *ngIf="relatedProducts.length">
              <div class="col-12 product-upsell text-center">
                <h4>Quem comprou este item também comprou...</h4>
              </div>
              <div class="row justify-content-center" id="upsell_product">
                <ng-container *ngFor="let product of relatedProducts | slice : 0 : 4">
                  <div class="product-box col-sm-3 col-6">
                    <div class="img-wrapper">
                      <div class="front">
                        <a
                          [routerLink]="['/shop/collection/infinitescroll/', product.id]"
                        >
                          <img
                            [src]="product.images[0]?.url"
                            class="img-fluid mb-1"
                            alt=""
                          />
                        </a>
                      </div>
                      <div class="product-detail">
                        <h6 class="mt-0">
                          <a
                            [routerLink]="['/shop/collection/infinitescroll/', product.id]"
                          >
                            <span>{{ product.product_name }}</span>
                          </a>
                        </h6>
                        <h4>
                          <span>{{ currencyService.toLocalCurrency(product.unit_price) }}</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Add to cart modal popup end-->
