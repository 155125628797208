import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

export interface ExpansionPanelGroupSelector {
  type: "group";
  group?: string;
  subgroup?: string;
  name?: string;
}

export interface ExpansionPanelUnitSelector {
  type: "unit";
  id?: string;
}

export type ExpansionPanelSelector =
  | ExpansionPanelGroupSelector
  | ExpansionPanelUnitSelector;

export type ExpansionPanelAction = "open" | "close" | "toggle";

export interface ExpansionPanelCommand {
  selector: ExpansionPanelSelector;
  action: ExpansionPanelAction;
}

@Injectable({
  providedIn: "root",
})
export class ExpansionPanelService {
  private commandSubject: Subject<ExpansionPanelCommand> =
    new Subject<ExpansionPanelCommand>();

  command$ = this.commandSubject.asObservable();

  open(selector: ExpansionPanelSelector): void {
    this.commandSubject.next({
      selector: selector,
      action: "open",
    });
  }

  close(selector: ExpansionPanelSelector): void {
    this.commandSubject.next({
      selector: selector,
      action: "close",
    });
  }

  toggle(selector: ExpansionPanelSelector): void {
    this.commandSubject.next({
      selector: selector,
      action: "toggle",
    });
  }
}
