<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>SAIBA DE TUDO QUE ACONTECE!</h4>
								<p>Inscreva-se na nossa newsletter e nunca perca uma novidade. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Digite seu e-mail">
							</div>
							<button type="submit" class="btn btn-solid">assinar</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6 mb-3">
					<div class="footer-title footer-mobile-title">
						<h4>Sobre</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Para você. No seu tempo.</p>
						<div class="footer-social">
              <ul>
                <li>
                  <a href="https://pt-br.facebook.com/Mreasyauto/" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/mr-easy-auto-569292201/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>                 
                </li>
                <li>
                  <a href="https://twitter.com/Mreasyauto1" taget="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/Mr_easy_auto/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC5LGj9uZTrMrLwmovzOZ8KQ" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
					</div>
				</div>
				<div class="col offset-xl-1 mb-3">
					<div class="sub-title">
						<div class="footer-title">
              <h4>Serviços</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a [routerLink]="['/shop/service/license-plate']">Emplacamento</a></li>
                <li><a href="javascript:void(0)">Vistoria</a></li>
                <li><a href="javascript:void(0)">Licenciamento</a></li>
                <li><a href="javascript:void(0)">Estacionamento</a></li>
                <li><a href="javascript:void(0)">Treinamentos</a></li>
              </ul>
            </div>
					</div>
				</div>
				<div class="col mb-3">
					<div class="sub-title">
						<div class="footer-title">
              <h4>Institucional</h4>
            </div>
            <div class="footer-contant">
              <ul>
                <li><a href="javascript:void(0)">Quem Somos</a></li>
                <li><a href="javascript:void(0)">Perguntas Frequentes</a></li>
                <li><a href="javascript:void(0)">Torne-se um Franqueado</a></li>
                <li><a href="javascript:void(0)">Torne-se um Parceiro</a></li>
                <li><a href="javascript:void(0)">Trabalhe Conosco</a></li>
              </ul>
            </div>
					</div>
				</div>
				<div class="col mb-3">
					<div class="sub-title">
						<div class="footer-title">
              <h4>Contato</h4>
						</div>
						<div class="footer-contant">
              <ul class="contact-list">
                <li><i class="fa fa-phone"></i>+55 11 2287-6414</li>
                <li><i class="fa fa-whatsapp"></i>+55 11 99406-8984</li>
                <li><i class="fa fa-envelope-o"></i><a>atendimento@mreasyauto.com.br</a></li>
                <li><i class="fa fa-map-marker"></i>Rua Conselheiro Saraiva, 543. Santana. São Paulo - SP. 02037-021</li>
              </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Mr. Easy Auto. Todos os direitos reservados.</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->