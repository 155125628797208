<div>
  <div class="sidebar-btn-container">
    <div class="sidebar-btn">
      <div class="theme-layout-version" (click)="layoutSidebarToggle()">
        <i class="fa fa-bell" aria-hidden="true"></i>
      </div>
    </div>
    <div class="sidebar-btn">
      <div class="theme-layout-version" (click)="customizeLayoutDark()">
        Dark
      </div>
    </div>
  </div>

  <div id="setting_box" class="setting-box" [class.opensetting]="layoutsidebar">
    <a
      href="javascript:void(0)"
      class="overlay"
      (click)="layoutSidebarToggle()"
    ></a>
    <div class="setting_box_body">
      <div>
        <div class="sidebar-back text-start" (click)="layoutSidebarToggle()">
          <i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Voltar
        </div>
      </div>
      <div class="setting-body">
        <div class="setting-title">
          <h4>Sem notificações para mostrar</h4>
        </div>
      </div>
    </div>
  </div>
</div>
