import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SettingsService } from "../settings.service";
import { UtilService } from "../util.service";

@Injectable({ providedIn: "root" })
export class ScheduleService {
  private get apiUrl(): string {
    return this.settingsService.apiUrl + "api/v1/agenda/";
  }

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private utilService: UtilService
  ) {}

  getSlotsAvailable(agendaId: number, filter = null): Observable<any> {
    const params = this.utilService.createHttpParams(filter);
    return this.http.get(
      this.apiUrl.concat(`public/${agendaId}/appointment_slot/available/`),
      { params: params }
    );
  }

  getNextSlotAvailable(agendaId: number, filter = null): Observable<any> {
    const params = this.utilService.createHttpParams(filter);
    return this.http.get(
      this.apiUrl.concat(`public/${agendaId}/appointment_slot/next_available/`),
      { params: params }
    );
  }
}
