import { HttpBackend, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import * as html2pdf from "html2pdf.js";
import { Observable, of, switchMap } from "rxjs";
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  private http: HttpClient;

  constructor(
    private handler: HttpBackend,
    private alertService: AlertService
  ) {
    this.http = new HttpClient(this.handler);
  }

  formatErrorMsg(err) {
    if (err.status === 0) {
      return "Não foi possível estabelecer uma conexão com o servidor.";
    }

    const error = err.error;
    const detail = error.detail;
    if (detail) return detail;
    return JSON.stringify(error);
  }

  getNonFieldErrors(errors): string[] {
    const nonFieldErrors = [];

    if (errors == null || (Array.isArray(errors) && !errors.length)) {
      return nonFieldErrors;
    }

    if (typeof errors === "string") {
      return nonFieldErrors;
    }

    if (Array.isArray(errors)) {
      for (let i = 0; i < errors.length; i++) {
        nonFieldErrors.push(...this.getNonFieldErrors(errors[i]));
      }
      return nonFieldErrors;
    }

    Object.entries(errors).forEach(([fieldName, fieldErrors]) => {
      if (fieldName === "non_field_errors" || fieldName === "detail") {
        if (typeof fieldErrors === "string") {
          nonFieldErrors.push(fieldErrors);
        } else if (fieldErrors instanceof Array) {
          nonFieldErrors.push(...fieldErrors);
        }
      } else {
        nonFieldErrors.push(...this.getNonFieldErrors(fieldErrors));
      }
    });
    return nonFieldErrors;
  }

  setFormErrors(control: AbstractControl, errors) {
    if (
      control == null ||
      errors == null ||
      (Array.isArray(errors) && !errors.length)
    )
      return;

    if (typeof errors === "string") {
      control.setErrors(
        { invalid: true, message: errors },
        { emitEvent: false }
      );
      return;
    }

    if (Array.isArray(errors)) {
      if (control instanceof FormArray) {
        for (let i = 0; i < errors.length; i++) {
          const childCtrl = control.controls[i];
          if (childCtrl != null) {
            this.setFormErrors(childCtrl, errors[i]);
          }
        }
        return;
      }

      if (errors.every((err) => typeof err === "string")) {
        control.setErrors(
          { invalid: true, message: errors },
          { emitEvent: false }
        );
      }
      return;
    }

    Object.entries(errors).forEach(([fieldName, fieldErrors]) => {
      const fieldControl = control.get(fieldName);
      this.setFormErrors(fieldControl, fieldErrors);
    });
  }

  clearFormErrors(control: AbstractControl) {
    control.setErrors(null, { emitEvent: false });

    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach(
        (fieldControl: AbstractControl) => {
          this.clearFormErrors(fieldControl);
        }
      );
    }

    if (control instanceof FormArray) {
      control.controls.forEach((fieldControl: AbstractControl) => {
        this.clearFormErrors(fieldControl);
      });
    }
  }

  createHttpParams(params = null, filterNull = true): HttpParams {
    if (params == null) {
      return new HttpParams();
    }

    const filterNullFn = (obj) => {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (filterNull && value == null) {
          return acc;
        }
        if (typeof value === "object" && !Array.isArray(value)) {
          acc[key] = filterNullFn(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
    };

    if (filterNull) {
      params = filterNullFn(params);
    }
    return new HttpParams({ fromObject: params });
  }

  downloadHtmlAsPdf(url: string, filename: string): Observable<boolean> {
    return this.http.get(url, { responseType: "text" }).pipe(
      switchMap((val) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = val;

        const pdfOptions = {
          margin: 10,
          filename: filename,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2pdf().from(tempElement).set(pdfOptions).save();
        return of(true);
      })
    );
  }
}
