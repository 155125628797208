import { Component, HostListener, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth/auth.service";
import { UserService } from "../../services/users/user.service";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent {
  @Input() class: string;
  @Input() themeLogo = "assets/images/icon/logo.png"; // Default Logo
  @Input() topbar = true; // Default True
  @Input() sticky = false; // Default false

  public stick = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService
  ) {}

  get isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  get firstName() {
    return this.userService.firstName;
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  logOut() {
    this.authService.logOut();
    this.router.navigate(["/"]);
  }
}
