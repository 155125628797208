import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root',
})
export class PubSubService extends RxStomp {
  constructor() {
    super();

    /**
     * @description Exemplo de como escutar um topico.
     *
     * this.pubSubService.watch('TOPICO').subscribe((message: Message) => {
        console.log(message);
      });
     */
  
  }

  
}