<div class="media d-flex align-items-center">
  <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
    <img
      [defaultImage]="
        ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'
      "
      [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].url"
      class="img-fluid lazy-loading"
      alt="{{ product.images[0].alt_text }}"
    />
  </a>
  <div class="media-body align-self-center">
    <div class="rating">
      <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    </div>
    <a [routerLink]="['/shop/collection/infinitescroll/', product.id]">
      <h6>{{ product.title | titlecase }}</h6>
    </a>
    <h4>
      {{
        product?.price * currencyConfig.price
          | discount : product
          | currency : currencyConfig.currency
      }}
    </h4>
    <ul class="color-variant">
      <li
        [class]="color"
        *ngFor="let color of Color(product?.variants)"
        [ngStyle]="{ 'background-color': color }"
        (click)="ChangeVariants(color, product)"
      ></li>
    </ul>
  </div>
</div>
